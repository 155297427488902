import { ReactNode, useEffect, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerItemProps,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import UserManagement from "./user-management/UserManagement";
import UserGroups from "./user-groups/UserGroups";
import AuthorizationProfiles from "./authorization-profile/AuthorizationProfiles";
import { AccessPermissionEnum } from "../../../enums";
import useAuth from "../../../hooks/useAuth";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../../hooks/useSwal";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface INavItem {
  text?: string;
  icon?: string;
  selected?: boolean;
  content?: ReactNode;
  key?: string;
}

const items: INavItem[] = [
  {
    text: "Users",
    icon: "bi bi-person",
    selected: true,
    content: <UserManagement />,
    key: "UsersTitle",
  },
  {
    text: "User Groups",
    icon: "bi bi-people",
    selected: false,
    content: <UserGroups />,
    key: "UserGroupsTitle",
  },
  {
    text: "Permission Groups",
    icon: "bi bi-shield-check",
    selected: false,
    content: <AuthorizationProfiles />,
    key: "PermissionGroupsTitle",
  },
];

interface UserControlProps {}

const UserControl: React.FC<UserControlProps> = () => {
  const localeCtx = useLocale();
  const swal = useSwal();
  const auth = useAuth();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [navItems, setNavItems] = useState<INavItem[]>([]);
  const [selectedId, setSelectedId] = useState<number>(
    0
    // items.findIndex((x) => x.selected === true)
  );

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["UserControl"]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("UserControl");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["UserControl"]
      );
      localeCtx?.setPreviousAppLocale("UserControl");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        await swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["UserControl"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    setNavItems(getTabItems());
  }, []);

  const getTabItems = () => {
    var _navItem = items
      .filter((item) => {
        if (
          (item.key === "UsersTitle" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)) ||
          (item.key === "UserGroupsTitle" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)) ||
          (item.key === "PermissionGroupsTitle" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl))
        ) {
          return true;
        } else {
          return false;
        }
      })
      .map((item, index) => {
        return {
          ...item,
          selected: index === selectedId,
        };
      });

    return _navItem;
  };

  const handleExpandChange = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleSelectionChange = (event: DrawerSelectEvent) => {
    navItems.forEach((i, idx) => {
      if (idx === event.itemIndex) {
        i.selected = true;
      } else {
        i.selected = false;
      }
    });
    setSelectedId(event.itemIndex);
    setExpanded(true);
  };

  const CustomItem = (props: DrawerItemProps) => {
    return (
      <DrawerItem title={expanded ? props.text : ""} {...props}>
        <span
          title={!expanded ? props.text : ""}
          className={"k-icon " + props.icon}
        />
        <span className={"k-item-text"}>{props.text}</span>
      </DrawerItem>
    );
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) &&
      !auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl) ? (
        <UnauthorizedAccess />
      ) : (
        <Tooltip
          anchorElement={"target"}
          position={expanded ? "top" : "right"}
          parentTitle={true}
        >
          <div className="mainDrawer navPushDown navPush p-t-55">
            <div className="mainDrawerInner">
              <div className="miniButton">
                <Button
                  icon="menu"
                  fillMode="flat"
                  onClick={handleExpandChange}
                >
                  <i className="bi bi-list"></i>
                </Button>
              </div>
              <Drawer
                expanded={expanded}
                position="start"
                mode="push"
                width={220}
                mini={true}
                items={navItems.map((item: INavItem) => {
                  item.text = translationsLoading
                    ? item.text
                    : fetchLabelKeyTranslation(item.key ?? "", item.text ?? "");
                  return item;
                })}
                onSelect={handleSelectionChange}
                item={CustomItem}
              >
                <DrawerContent>
                  <div className="contentPush">
                    {navItems.find((item) => item.selected === true)?.content}
                  </div>
                </DrawerContent>
              </Drawer>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default UserControl;
