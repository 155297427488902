import { Button } from "@progress/kendo-react-buttons";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import Transcription from "./Transcription";
import CallSummary from "./CallSummary";
import jwt_decode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import CallHistory from "./CallHistory";
import { CallDetail } from "../../types/call";
import ConversationFlow from "./ConversationFlow";
import { Loader } from "@progress/kendo-react-indicators";
import RecordingScorecardTab from "./RecordingScorecardTab";
import ContentPlaceholder from "../../components/ContentPlaceholder";
import RecordingComments from "./RecordingComments";
import { useNavigate } from "react-router-dom";
import RecordingTrack from "./RecordingTrack";
import {
  extractExactMatchTopicsInsights,
  extractSegmentSentimentInsights,
  extractTranscriptInsights,
} from "../../utils/trackInsightUtils";
import {
  Comment,
  RecordingMoment,
  RecordingSegmentSentiment,
  RecordingTopic,
  RecordingTranscription,
} from "../../types/recording";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../../utils/customAxios";
import FileDownload from "js-file-download";
import { RecordingTrackSegment } from "../../types/player-track";
import "./CallDetails.scss";
import CallPlayer from "../../components/custom/CallPlayer";
import CustomUserInfo from "../../components/custom/CustomUserInfo";
import { formatDateTime, formatSecondsToTime } from "../../utils/dateTimeUtils";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AccessPermissionEnum } from "../../enums/accessPermissionEnum";
import useAuth from "../../hooks/useAuth";
import ShareDialog from "../../components/ShareDialog";
import callsService from "../../services/calls.service";
import recordingsService from "../../services/recordings.service";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import { navData } from "../../data/NavigationData";
import CallAdditionalInfoDialog from "./CallAdditionalInfoDialog";
import Alert from "../../components/custom/Alert";
import { IsValidString } from "../../utils/stringUtils";
import CallFlag from "../../components/custom/CallFlag";
import { ColorCategory } from "../../types/master-data";
import { User } from "../../types/user";
import usersService from "../../services/users.service";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import AuditLogSettings from "../settings/system-settings/audit-log-settings/AuditLogSettings";

import IcsLogo from "../../assets/images/IcsLogo.png";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import products from "./callLegs.json";
import { RecordingLockedSegment } from "../../types/recording/RecordingLockedSegment";
import ParticipantPopup from "../../components/custom/ParticipantPopup";
import useMasterData from "../../hooks/useMasterData";
import { load } from "@progress/kendo-react-intl";
import { TokenPayload } from "../../types/context";
import useSessionStorage from "../../hooks/useSessionStorage";

export interface RegionSelection {
  start: number;
  end: number;
}

export interface CommentRegionSelection {
  commentId: number;
  start: number;
  end: number;
}

export interface LockedRegionSelection {
  start: number;
  end: number;
}

interface CallDetailsProps {
  sharedProp?: boolean;
}

export interface kendoAlertData {
  popup: boolean;
  style: any;
  message: string;
}

const topicRelevanceColor = (t: RecordingTopic) => {
  var topicColor = "";
  if (t.topicRelevance === null) {
    topicColor = "bg-greenDarkDLL";
  } else if (t.topicRelevance > 0.9) {
    topicColor = "bg-greenDLL";
  } else if (t.topicRelevance > 0.6) {
    topicColor = "bg-yellowDLL";
  }
  return topicColor;
};

const CallDetails: React.FC<CallDetailsProps> = ({ sharedProp }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const localeCtx = useLocale();
  const swal = useSwal();
  const auth = useAuth();
  const params = useParams();
  const masterData = useMasterData();
  const { platformCallDetailId } = params;
  const [searchParams, setSearchParams] = useSearchParams();
  const recordingId = searchParams.get("recordingId");

  const [shared, setShared] = useState<boolean>(sharedProp ?? false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [regionSelection, setRegionSelection] = useState<RegionSelection>({
    start: 0,
    end: 0,
  });
  const [recordingDuration, setRecordingDuration] = useState<number>(0);
  const [playbackProgress, setPlaybackProgress] = useState<number>(0);
  const [callDetail, setCallDetail] = useState<CallDetail | undefined>();
  const [callDetailLoading, setCallDetailLoading] = useState<boolean>(true);
  const [callDetailError, setCallDetailError] = useState<string | undefined>();
  const [showUpgradeLicense, setShowUpgradeLicense] = useState<boolean>(false);
  const [rawTranscript, setRawTranscript] = useState<
    RecordingTranscription | undefined
  >();
  const [rawTranscriptLoading, setRawTranscriptLoading] =
    useState<boolean>(true);
  const [rawTranscriptError, setRawTranscriptError] = useState<
    string | undefined
  >();
  // const [recMoments, setRecMoments] = useState<RecordingMoment[] | undefined>();
  // const [recMomentsLoading, setRecMomentsLoading] = useState<boolean>(true);
  // const [recMomentsError, setRecMomentsError] = useState<string | undefined>();
  const [recTopics, setRecTopics] = useState<RecordingTopic[] | undefined>();
  const [recTopicsLoading, setRecTopicsLoading] = useState<boolean>(true);
  const [recTopicsError, setRecTopicsError] = useState<string | undefined>();
  const [recSentiments, setRecSentiments] = useState<
    RecordingSegmentSentiment[] | undefined
  >();
  const [recSentimentsLoading, setRecSentimentsLoading] =
    useState<boolean>(true);
  const [recSentimentsError, setRecSentimentsError] = useState<
    string | undefined
  >();
  const [participantsTrackInsight, setParticipantsTrackInsight] = useState<
    RecordingTrackSegment[] | undefined
  >();
  const [exactMatchTopicInsights, setExactMatchTopicsTrackInsight] = useState<
    RecordingTrackSegment[] | undefined
  >();
  const [sentimentsTrackInsight, setSentimentsTrackInsight] = useState<
    RecordingTrackSegment[] | undefined
  >();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["CallDetail"]);
  const [additionalInfoDialogVisible, setAdditionalInfoDialogVisible] =
    useState<boolean>(false);
  const [recordingComments, setRecordingComments] = useState<
    Comment[] | undefined
  >();
  const [recordingCommentsError, setRecordingCommentsError] = useState<
    string | undefined
  >();
  const [commentRegions, setCommentRegions] = useState<
    CommentRegionSelection[]
  >([]);
  const [highlightCommentRegion, setHighlightCommentRegion] = useState<
    Comment | undefined
  >();
  const [playCommentRegion, setPlayCommentRegion] = useState<
    Comment | undefined
  >();
  const [lockedRegions, setLockedRegions] = useState<
    LockedRegionSelection[] | undefined
  >();
  const [audioLoaded, setAudioLoaded] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<kendoAlertData>({
    popup: false,
    style: "",
    message: "",
  });
  const [trackSpanTime, setTrackSpanTime] = useState<number | undefined>();
  const [shareDialogVisible, setShareDialogVisible] = useState<boolean>(false);
  const [audioLoadedErrored, setAudioLoadedErrored] = useState<boolean>(false);
  const [recordingTrackLineClicked, setRecordingTrackLineClicked] =
    useState<boolean>(false);
  const [currentUserData, setCurrentUserData] = useState<User>();
  const [isMarkedForRetention, setIsMarkedForRetention] =
    useState<boolean>(false);
  const [totalCommentsTemp, setTotalCommentsTemp] = useState<
    number | undefined
  >(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [commentShared, setCommentShared] = useState<boolean>(true);
  const [evalShared, setEvalShared] = useState<boolean>(true);
  const [outcomeShared, setOutcomeShared] = useState<boolean>(true);
  const [flagShared, setFlagShared] = useState<boolean>(true);
  const [participantHistoryShared, setParticipantHistoryShared] =
    useState<boolean>(true);
  const [auditLogsShared, setAuditLogsShared] = useState<boolean>(true);
  const [sharedSum, setSharedSum] = useState<number>(0);
  const [loadCallDetail, setLoadCallDetail] = useState<boolean>(sharedProp === true ? false : true);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["CallDetail"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (!recordingId) {
      navigate("/not-found", { replace: true });
    }
    if (shared) {
      const shareToken = sessionStorage.getItem("ShareToken");
      if (shareToken) {
        const decodedToken = jwt_decode<TokenPayload>(shareToken);
        if (decodedToken) {
          setSharedSum(decodedToken?.SharedSum);
          //if internally shared resource
          if (!decodedToken.GuestUserEmail) {
            checkInternalUserCallDetailAccess();
          }
          else
          {
            setLoadCallDetail(true);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (loadCallDetail) {
      if (!recordingId) {
        navigate("/not-found", { replace: true });
      }
      var selectedTabViaNavigation = searchParams.get("selectedtab");
      if (selectedTabViaNavigation && selectedTabViaNavigation.trim() !== "") {
        setSelectedTab(parseInt(selectedTabViaNavigation));
      }

      if (shared) {
        if (Number(sharedSum & 1) === 0) {
          setCommentShared(false);
        }
        if (Number(sharedSum & 2) === 0) {
          setEvalShared(false);
        }
        if (Number(sharedSum & 4) === 0) {
          setOutcomeShared(false);
        }
        if (Number(sharedSum & 8) === 0) {
          setParticipantHistoryShared(false);
        }
        if (Number(sharedSum & 16) === 0) {
          setAuditLogsShared(false);
        }
        if (Number(sharedSum & 32) === 0) {
          setFlagShared(false);
        }
      }

      sendFetchCallDetailRequest();
      sendFetchTranscriptionRequest();
      // sendFetchRecMomentsRequest();
      sendFetchRecTopicsRequest();
      sendFetchRecSegmentSentimentsRequest();
      sendFetchRecordingCommentsRequest();
      sendFetchRecordingLockedSegmentsRequest();
    }
  }, [
    recordingId,
    platformCallDetailId,
    navigate,
    searchParams,
    recordingDuration,
    loadCallDetail,
    masterData?.data,
  ]);

  useEffect(() => {
    if (loadCallDetail) {
      if (rawTranscript && recordingDuration > 0) {
        setParticipantsTrackInsight(
          extractTranscriptInsights(rawTranscript, recordingDuration)
        );
      }
      setSentimentsTrackInsight(
        extractSegmentSentimentInsights(recSentiments ? recSentiments : [])
      );
    }
  }, [
    rawTranscript,
    recSentiments,
    recTopics,
    recordingDuration,
    loadCallDetail,
  ]);

  useEffect(() => {
    if (loadCallDetail) {
      if (alertData.popup) {
        setTimeout(() => {
          setAlertData({ ...alertData, popup: false });
        }, 5000);
      }
    }
  }, [alertData.popup, loadCallDetail]);

  useEffect(() => {
    if (loadCallDetail) {
      const getCurrentUser = async () => {
        try {
          if (callDetail) {
            const currentUser = await usersService.getUser(
              callDetail?.firstParty?.id?.toString()
            );
            setCurrentUserData(currentUser);
          }
        } catch (err) {
          console.error(err);
        }
      };
      if (!shared || (shared && auth?.accessToken)) {
        getCurrentUser();
      }
    }
  }, [callDetail, shared, loadCallDetail]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("CallDetail");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["CallDetail"]
      );
      localeCtx?.setPreviousAppLocale("CallDetail");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected !== 1) {
      setPlayCommentRegion(undefined);
    }
    setSelectedTab(e.selected);
  };

  const checkInternalUserCallDetailAccess = async () => {
    const response = await callsService.checkUserCallDetailAccess(
      platformCallDetailId ?? ""
    );
    if (response) {
      setShared(false);
    }
    setLoadCallDetail(true);
  };

  const sendFetchCallDetailRequest = async () => {
    try {
      setCallDetailLoading(true);
      const data = await callsService.fetchCallByPlatformCallDetailId(
        platformCallDetailId ? platformCallDetailId : "",
        shared === true
      );
      if (recordingId && parseInt(recordingId) !== data.recordings[0].id) {
        navigate("/unauthorized-calldetail", { replace: true });
      }
      setCallDetail(data);
      setShowUpgradeLicense(!data.recordings[0].isAIAnalysed);
      setIsMarkedForRetention(data.recordings[0].isMarkedForRetention);
      setTotalCommentsTemp(data.recordings[0].totalComments);
    } catch (err) {
      if (err instanceof AxiosError) {
        setCallDetailError(err.message);
        if (err.response?.status === 501) {
          navigate("/error", { replace: true });
        } else if (err.response?.status === 404) {
          navigate("/not-found", { replace: true });
        } else {
          navigate("/unauthorized-calldetail", { replace: true });
        }
      }
    } finally {
      setCallDetailLoading(false);
      setRawTranscriptLoading(false);
      // setRecMomentsLoading(false);
      setRecTopicsLoading(false);
      setRecSentimentsLoading(false);
    }
  };

  const sendFetchTranscriptionRequest = async () => {
    try {
      setRawTranscriptLoading(true);
      const data = await recordingsService.fetchRecordingTranscription(
        parseInt(recordingId!),
        shared === true
      );
      setRawTranscript(data);
    } catch (err) {
      if (err instanceof Error) {
        if (err instanceof AxiosError) {
          setRawTranscriptError(
            err?.response?.data?.error ? err.response.data.error : err.message
          );
        }
      }
    }
  };

  // const sendFetchRecMomentsRequest = async () => {
  //   try {
  //     setRecMomentsLoading(true);
  //     const data = await recordingsService.fetchRecordingMoments(
  //       parseInt(recordingId!),
  //       shared === true
  //     );
  //     setRecMoments(data);
  //   } catch (err) {
  //     if (err instanceof Error) {
  //       setRecMomentsError(err.message);
  //     }
  //   }
  // };

  const sortingFunc = (p1: RecordingTopic, p2: RecordingTopic) => {
    return Math.round(p1.topicRelevance * 100) <
      Math.round(p2.topicRelevance * 100)
      ? 1
      : Math.round(p1.topicRelevance * 100) >
        Math.round(p2.topicRelevance * 100)
      ? -1
      : 0;
  };

  const sendFetchRecTopicsRequest = async () => {
    try {
      setRecTopicsLoading(true);
      const data = await recordingsService.fetchRecordingTopics(
        parseInt(recordingId!),
        shared === true
      );
      const extendedSearchTopic = data.filter((t) => t.topicRelevance !== null);
      const sortedextendedSearchTopic = extendedSearchTopic.sort(sortingFunc);
      setRecTopics(sortedextendedSearchTopic);
      const exactMatchTopic = data?.filter((t) => t.topicRelevance === null);
      if (exactMatchTopic) {
        setExactMatchTopicsTrackInsight(
          extractExactMatchTopicsInsights(exactMatchTopic, recordingDuration)
        );
      }
    } catch (err) {
      if (err instanceof Error) {
        setRecTopicsError(err.message);
      }
    }
  };

  const sendFetchRecSegmentSentimentsRequest = async () => {
    try {
      setRecSentimentsLoading(true);
      const data = await recordingsService.fetchRecordingSegmentSentiments(
        parseInt(recordingId!),
        shared === true
      );
      setRecSentiments(data);
      setSentimentsTrackInsight(extractSegmentSentimentInsights(data));
    } catch (err) {
      if (err instanceof Error) {
        setRecSentimentsError(err.message);
      }
    }
  };

  const sendFetchRecordingCommentsRequest = async () => {
    try {
      const data = await recordingsService.fetchRecordingComments(
        parseInt(recordingId!),
        shared === true
      );
      setRecordingComments(data);
      // adding comment regions
      const regions: CommentRegionSelection[] = [];
      data.forEach((c) => {
        if (c.segmentStart && c.segmentEnd) {
          regions.push({
            commentId: c.id,
            start: c.segmentStart,
            end: c.segmentEnd,
          });
        }
      });
      setCommentRegions(regions);
    } catch (err) {
      if (err instanceof Error) {
        setRecordingCommentsError(err.message);
      }
    }
  };
  const sendFetchRecordingLockedSegmentsRequest = async () => {
    try {
      const data: RecordingLockedSegment[] =
        await recordingsService.fetchLockedSegments(
          parseInt(recordingId!),
          shared === true
        );
      // adding locked regions
      const regions: LockedRegionSelection[] = [];
      data.forEach((c) => {
        if (c.startTime && c.endTime) {
          regions.push({
            start: c.startTime,
            end: c.endTime,
          });
        }
      });
      setLockedRegions(regions);
    } catch (err) {
      if (err instanceof Error) {
      }
    }
  };

  const sendRetainRecordingRequest = async () => {
    try {
      const response = await recordingsService.retainRecording(
        parseInt(recordingId!),
        !isMarkedForRetention,
        shared === true
      );
      if (response) {
        setIsMarkedForRetention((prev) => !prev);
      }
    } catch (err) {
      const swalOptions: SweetAlertOptions<any, any> = {
        icon: "error",
        title: "Error",
        text: "Failed to Hold/Release recording. Please try again.",
      };
      swal.fire(swalOptions);
    }
  };

  const sendDeleteRecordingRequest = async () => {
    const swalOptions: SweetAlertOptions<any, any> = {
      icon: "info",
      title: `${
        translationsLoading
          ? "Are you sure you want to delete this recording?"
          : fetchLabelKeyTranslation(
              "SwtAltCallDeletedTitle",
              "Are you sure you want to delete this recording?"
            )
      }`,
      text: `${
        translationsLoading
          ? "This recording will be deleted"
          : fetchLabelKeyTranslation(
              "SwtAltCallDeletedText",
              "This recording will be deleted"
            )
      }`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${
        translationsLoading
          ? "Delete"
          : fetchLabelKeyTranslation(
              "SwtAltConfirmToDeleteCallButtonText",
              "Delete"
            )
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("CancelButtonText", "Cancel")
      }`,
    };
    const resp = await swal.fire(swalOptions);
    if (resp.isConfirmed) {
      try {
        const response = await recordingsService.deleteRecording(
          parseInt(recordingId!),
          shared === true
        );
        if (response) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "success",
            title: "Recording deleted.",
            text: "Recording has been deleted successfully.",
          };
          swal.fire(swalOptions).then(async (result) => {
            if (result.isConfirmed) {
              window.close();
            }
          });
        }
      } catch (err) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Failed to delete recording. Please try again.",
        };
        swal.fire(swalOptions);
      }
    }
  };

  const onUpdateCallDetailProperties = (updatedCallDetail: CallDetail) => {
    setCallDetail(updatedCallDetail);
  };

  const onScorecardEvaluated = useCallback(() => {
    sendFetchCallDetailRequest();
  }, []);

  const onCommentMouseOver = (commentId: number) => {
    const comment = recordingComments?.find((c) => c.id === commentId);
    if (comment) {
      setHighlightCommentRegion(comment);
    }
  };

  const onCommentMouseOut = () => {
    setHighlightCommentRegion(undefined);
  };

  const onUserCommentPlay = (comment: Comment) => {
    if (audioLoaded) {
      setPlayCommentRegion(comment);
    }
  };

  const onUserCommentStop = () => {
    setPlayCommentRegion(undefined);
  };

  const onUserSeekOutOfCommentRegion = () => {
    setPlayCommentRegion(undefined);
  };

  const downloadRecording = async () => {
    var response = await axiosWithAuth.get(
      `recording/${recordingId}/download`,
      { responseType: "arraybuffer" }
    );
    FileDownload(response.data, `${recordingId}_callRecording.wav`);
  };

  const toggleShareDialogHandler = () => {
    setShareDialogVisible((prev) => !prev);
  };

  const onCloseShareDialog = () => {
    setShareDialogVisible(false);
  };

  const onRecordingTrackSpanPlay = (time: number) => {
    setTrackSpanTime(time);
    setRecordingTrackLineClicked(!recordingTrackLineClicked);
  };

  const onAddComment = (newComment: Comment) => {
    if (recordingComments) {
      setRecordingComments([newComment, ...recordingComments]);
      if (newComment.segmentStart && newComment.segmentEnd) {
        setCommentRegions([
          ...commentRegions,
          {
            commentId: newComment.id,
            start: newComment.segmentStart,
            end: newComment.segmentEnd,
          },
        ]);
      }
    }
    setRegionSelection({
      start: 0,
      end: recordingDuration,
    });
  };

  const onDeleteComment = (commentId: number) => {
    if (recordingComments) {
      setRecordingComments((prevState) => {
        return prevState?.filter((c) => c.id !== commentId);
      });
      setCommentRegions((prevState) => {
        return prevState.filter((c) => c.commentId !== commentId);
      });
    }
  };

  const onUpdateComment = (updatedComment: Comment) => {
    if (recordingComments) {
      const updatedCommentsList: Comment[] = [];
      recordingComments.forEach((c) => {
        if (c.id === updatedComment.id) {
          updatedCommentsList.push(updatedComment);
        } else {
          updatedCommentsList.push(c);
        }
      });
      setRecordingComments(updatedCommentsList);
    }
  };

  const onDialogVisibleToggle = () => {
    setAdditionalInfoDialogVisible((prevState) => !prevState);
  };

  const handleFlagSelection = async (selectedFlag: ColorCategory) => {
    if (callDetail) {
      await callsService
        .createCallDetailCategory(callDetail.platformCallDetailId, selectedFlag)
        .then((response) => {
          setCallDetail({ ...callDetail, colorCategory: selectedFlag });
        })
        .catch((err) => {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: "Error",
            text: "Failed to update call flag. Please try again.",
          };
          swal.fire(swalOptions);
        });
    }
  };

  const IcsAnalytics = (props: { tab: any }) => {
    return (
      <>
        <div className="tabIcsAnalytics">
          <img className="tabIcsAnalyticsImg" src={IcsLogo} alt="" />
          <span>Analytics</span>
        </div>
      </>
    );
  };
  const CustomCellUser = (props: any) => (
    <>
      <td>
        <div className="d-flex align-items-center">
          <div className="tblUsr round-sm2 m-r-10">
            <div className="topUsrAreaPic">
              <div className="topUsrAreaPic-i">
                <div className="contact-list-icon bg-thistle">
                  <div className="contact-list-icon-txt">SR</div>
                </div>
              </div>
              <div className="topUsrAreaPic-tx">
                <span className="text-primary font-weight-bold-600 cursor-default text-decoration-none float-left w-100 line-height-1 text-left">
                  Steven Rosers
                </span>
              </div>
            </div>
          </div>
        </div>
      </td>
    </>
  );
  useEffect(() => {
    localStorage.setItem("callPlayerState", "callDetailsPage");
  }, []);

  return (
    <div className="call-details m-b-20">
      {additionalInfoDialogVisible && (
        <CallAdditionalInfoDialog
          platformCallDetailId={callDetail?.platformCallDetailId ?? ""}
          data={callDetail?.callAdditionalInfo ?? {}}
          onDialogClose={onDialogVisibleToggle}
          fetchLabelKeyTranslation={fetchLabelKeyTranslation}
          translationsLoading={translationsLoading}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="card cardEffect callDetailBody">
            {alertData.popup && (
              <Alert setAlertData={setAlertData} alertData={alertData} />
            )}
            <div
              className="card-header bg-white d-flex justify-content-between callDetailHdr"
              style={{ borderBottomColor: "rgba(0, 0, 0, 0.07)", overflow: "auto" }}
            >
              <span className="detailHeader d-flex align-items-center">
                <span className="fs-16 font-weight-semi p-r-20">
                  {`${
                    translationsLoading
                      ? "Call Details"
                      : fetchLabelKeyTranslation("CallDetails", "Call Details")
                  }`}
                </span>
                {callDetail && (
                  <div className="listInr-detail d-flex">
                    <CustomUserInfo
                      userInfo={callDetail.firstParty!}
                      customPnlCss={"liDetail d-flex align-items-center p-r-5"}
                      customDataHeaderCss={
                        "liDetail-h font-semi-bold text-balck-10 fs-14"
                      }
                      customDataBodyCss={
                        "liDetail-p fs-13 text-black-8 line-height-1"
                      }
                    />
                    <CustomUserInfo
                      userInfo={callDetail.secondParties.find((sp: any) => sp.id === 0) ?? callDetail.secondParties[0]}
                      customPnlCss={
                        "liDetail d-flex align-items-center p-l-10 m-l-15 border-left-solid border-w-2 border-black-2"
                      }
                      customDataHeaderCss={"liDetail-h fs-13 text-black-14"}
                      customDataBodyCss={
                        "liDetail-p fs-13 text-black-8 line-height-1"
                      }
                      customDataHeaderLabel={callDetail.secondParties.find((sp: any) => sp.id === 0)?.email ?? callDetail.secondParties[0].email}
                      customDataBodyLabel={
                        translationsLoading
                          ? "Remote Party"
                          : fetchLabelKeyTranslation(
                              "TextRemoteParty",
                              "Remote Party"
                            )
                      }
                    />
                    {callDetail.secondParties.length > 1 && (
                      <div className="moreDataInr align-items-center d-flex">
                        <Tooltip
                          anchorElement={"target"}
                          position={"top"}
                          parentTitle={true}
                        >
                          <div className="moreDataList">
                            <div
                              className="moreDataListBtn"
                              style={{ cursor: "pointer" }}
                              title={`${
                                translationsLoading
                                  ? "More Participants"
                                  : fetchLabelKeyTranslation(
                                      "lblMoreParticipants",
                                      "More Participants"
                                    )
                              }`}
                              onClick={toggleDialog}
                            >
                              <i className="bi bi-caret-down-fill text-black-9"></i>
                            </div>
                            {visible && (
                              <ParticipantPopup
                                toggleDialog={toggleDialog}
                                participants={callDetail.secondParties}
                              ></ParticipantPopup>
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    )}
                    <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-black-2">
                      <div className="liDetail-data">
                        <div className="liDetail-h fs-13 text-balck-14">
                          {callDetail.platform.name}
                        </div>
                        <div className="liDetail-p fs-13 text-black-8 line-height-1">
                          {`${
                            translationsLoading
                              ? "Platform"
                              : fetchLabelKeyTranslation(
                                  "TextPlatform",
                                  "Platform"
                                )
                          }`}
                        </div>
                      </div>
                    </div>
                    <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-black-2">
                      <div className="liDetail-data">
                        <div className="liDetail-h fs-13 text-balck-14">
                          {formatDateTime(
                            callDetail.callTime,
                            "MMM DD, YYYY, HH:mm"
                          )}
                        </div>
                        <div className="liDetail-p fs-13 text-black-8 line-height-1">
                          {`${
                            translationsLoading
                              ? "Date/Time"
                              : fetchLabelKeyTranslation(
                                  "TextDateTime",
                                  "Date/Time"
                                )
                          }`}
                        </div>
                      </div>
                    </div>
                    <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-black-2">
                      <div className="liDetail-data">
                        <div className="liDetail-h fs-13 text-balck-14">
                          {formatSecondsToTime(callDetail.callDuration)}
                        </div>
                        <div className="liDetail-p fs-13 text-black-8 line-height-1">{`${
                          translationsLoading
                            ? "Duration"
                            : fetchLabelKeyTranslation(
                                "TitleDuration",
                                "Duration"
                              )
                        }`}</div>
                      </div>
                    </div>
                    {flagShared && (
                      <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-black-2">
                        <div className="liDetail-data">
                          <div className="liDetail-h fs-13 text-balck-14">
                            <CallFlag
                              colorCategory={callDetail.colorCategory}
                              handleFlagSelection={handleFlagSelection}
                            />
                          </div>
                          <div className="liDetail-p fs-13 text-black-8">{`${
                            translationsLoading
                              ? "Call Flag"
                              : fetchLabelKeyTranslation(
                                  "FieldCallFlag",
                                  "Call Flag"
                                )
                          }`}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </span>
              {!shared && (
                <div className="callDetailAction d-flex align-items-center">
                  <Tooltip
                    anchorElement={"target"}
                    position={"top"}
                    parentTitle={true}
                  >
                    <div className="buttons-container hov-transparent d-flex align-itmes-center">
                      {auth?.checkUserAccess(
                        AccessPermissionEnum.ManageCompliance
                      ) &&
                        !isMarkedForRetention && (
                          <Button className="radius-50 iBtn-bg text-primary d-flex align-items-center justify-conter m-r-3">
                            <span
                              className="fs-20 line-height-1"
                              title={`${
                                translationsLoading
                                  ? "Hold/Retain Call"
                                  : fetchLabelKeyTranslation(
                                      "HoldRetainCallText",
                                      "Hold/Retain Call"
                                    )
                              }`}
                            >
                              <i
                                onClick={sendRetainRecordingRequest}
                                className="bi bi-h-circle"
                              ></i>
                            </span>
                          </Button>
                        )}

                      {auth?.checkUserAccess(
                        AccessPermissionEnum.ManageCompliance
                      ) &&
                        isMarkedForRetention && (
                          <Button className="radius-50 iBtn-bg text-primary d-flex align-items-center justify-conter m-r-3">
                            <span
                              className="fs-20 line-height-1"
                              title="Release"
                            >
                              <i
                                onClick={sendRetainRecordingRequest}
                                className="bi bi-r-circle"
                              ></i>
                            </span>
                          </Button>
                        )}
                      {auth?.checkUserAccess(
                        AccessPermissionEnum.DeleteCalls
                      ) && (
                        <Button
                          className={`radius-50 iBtn-bg text-primary d-flex align-items-center justify-conter m-r-3
                      ${
                        isMarkedForRetention || shared
                          ? "bg-black-2 text-black-5 border-0"
                          : ""
                      }
                    `}
                          disabled={isMarkedForRetention || shared}
                        >
                          <span
                            className="fs-17 line-height-1"
                            title={`${
                              translationsLoading
                                ? "Delete Call"
                                : fetchLabelKeyTranslation(
                                    "DeleteCallText",
                                    "Delete Call"
                                  )
                            }`}
                          >
                            <i
                              onClick={sendDeleteRecordingRequest}
                              className="bi bi-trash3"
                            ></i>
                          </span>
                        </Button>
                      )}
                      <Button
                        className={
                          "radius-50 iBtn-bg text-primary d-flex align-items-center justify-conter m-r-3"
                        }
                      >
                        <span className="fs-20 line-height-1">
                          <i
                            onClick={onDialogVisibleToggle}
                            title={`${
                              translationsLoading
                                ? "Additional Info"
                                : fetchLabelKeyTranslation(
                                    "TextAdditionalInformation",
                                    "Additional Info"
                                  )
                            }`}
                            className="bi bi-info-circle"
                          ></i>
                        </span>
                      </Button>
                      {auth?.accessToken && (
                        <Button
                          className={`radius-50 iBtn-bg text-primary d-flex align-items-center justify-conter m-r-3 ${
                            auth?.checkUserAccess(
                              AccessPermissionEnum.ShareCalls
                            )
                              ? "iconBtn"
                              : "disabledIconBtn"
                          }`}
                          disabled={
                            !auth?.checkUserAccess(
                              AccessPermissionEnum.ShareCalls
                            )
                          }
                        >
                          <span className="fs-17 line-height-1">
                            <i
                              className="bi bi-share"
                              title={`${
                                translationsLoading
                                  ? "Share"
                                  : fetchLabelKeyTranslation(
                                      "TextShare",
                                      "Share"
                                    )
                              }`}
                              onClick={toggleShareDialogHandler}
                            ></i>
                          </span>

                          {shareDialogVisible && callDetail && (
                            <ShareDialog
                              onClose={onCloseShareDialog}
                              resourceObjId={callDetail.id}
                              recordingPath={
                                window.location.pathname +
                                window.location.search
                              }
                            />
                          )}
                        </Button>
                      )}
                      {auth?.accessToken && (
                        <Button
                          className={`radius-50 iBtn-bg text-primary d-flex align-items-center justify-conter m-r-3 ${
                            audioLoaded
                              ? "iconBtn"
                              : "bg-black-2 text-black-5 border-0"
                          }`}
                          onClick={downloadRecording}
                          disabled={
                            !audioLoaded ||
                            !auth?.checkUserAccess(
                              AccessPermissionEnum.ShareCalls
                            )
                          }
                        >
                          <span className="fs-17 line-height-1">
                            <i
                              className="bi bi-download"
                              title={`${
                                translationsLoading
                                  ? "Download"
                                  : fetchLabelKeyTranslation(
                                      "TextDownload",
                                      "Download"
                                    )
                              }`}
                            ></i>
                          </span>
                        </Button>
                      )}
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
            {callDetail?.recordings[0].isAIAnalysed &&
              (auth?.checkTopicAvailibility() ||
                (shared == true && auth?.checkTopicAvailibilityForShared())) && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="detailTopic border-bottom-solid border-black-1 border-w-1 p-l-17 p-t-5 p-b-0 bg-white d-flex justify-content-between">
                      <span className="detailHeader d-flex align-items-center">
                        <span
                          className="fs-15 font-weight-semi p-r-20"
                          style={{ marginTop: "-5px" }}
                        >
                          {`${
                            translationsLoading
                              ? "Topics"
                              : fetchLabelKeyTranslation("TextTopics", "Topics")
                          }`}
                        </span>
                        <div className="p-r-15 p-l-15 bg-white w-100 float-left">
                          <div className="mx-th-tag">
                            <div className="mx-td-spn">
                              <div className="keywordRow">
                                {recTopics?.map(
                                  (topic: RecordingTopic, index: number) => (
                                    <div
                                      className={
                                        "keywordTrk " +
                                        topicRelevanceColor(topic)
                                      }
                                      key={index}
                                    >
                                      <Tooltip
                                        anchorElement={"target"}
                                        position={"right"}
                                        parentTitle={true}
                                      >
                                        <span
                                          title={
                                            topic.topicRelevance !== null
                                              ? `Relevance: ${Math.round(
                                                  topic.topicRelevance * 100
                                                )}%`
                                              : ""
                                          }
                                        >
                                          <i
                                            className={`bi ${
                                              topic.topicName.isPublic
                                                ? "bi-people"
                                                : "bi-lock-fill"
                                            } m-r-6`}
                                          ></i>
                                          {topic.topicName.name}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="p-t-10 p-r-15 p-b-0 p-l-15 bg-white w-100 float-left">
                    <CallPlayer
                      recordingId={parseInt(recordingId!)}
                      enableRegions={true}
                      showSkipSilenceBtn={true}
                      setRegionSelection={setRegionSelection}
                      setPlaybackProgress={setPlaybackProgress}
                      setRecordingDuration={setRecordingDuration}
                      highlightCommentRegion={highlightCommentRegion}
                      playCommentRegion={playCommentRegion}
                      trackSpanTime={trackSpanTime}
                      setAudioLoaded={setAudioLoaded}
                      commentRegions={commentRegions}
                      lockedRegions={lockedRegions}
                      setLockedRegions={setLockedRegions}
                      onUserSeekOutOfCommentRegion={
                        onUserSeekOutOfCommentRegion
                      }
                      setAudioLoadedErrored={setAudioLoadedErrored}
                      recordingTrackLineClicked={recordingTrackLineClicked}
                      wavformContainerId="callDetailsPage"
                      durationInSeconds={recordingDuration}
                      segments={participantsTrackInsight}
                      setSelectedTab={setSelectedTab}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="top-player mb-2 p-t-5 p-r-18 p-l-15 border-black-2 border-bottom-solid border-bottom-w-1">
                    {audioLoaded && !audioLoadedErrored && (
                      <div
                        className={`${showUpgradeLicense ? "blurArea3" : ""}`}
                      >
                        <RecordingTrack
                          trackLabel={`${
                            translationsLoading
                              ? "Participation"
                              : fetchLabelKeyTranslation(
                                  "LabelParticipants",
                                  "Participation"
                                )
                          }`}
                          duration={recordingDuration}
                          segments={participantsTrackInsight}
                          onTrackSpanPlay={onRecordingTrackSpanPlay}
                          error={rawTranscriptError}
                          isAIAnalysed={callDetail?.recordings[0].isAIAnalysed}
                        />
                        <RecordingTrack
                          trackLabel={`${
                            translationsLoading
                              ? "Exact Match Topics"
                              : fetchLabelKeyTranslation(
                                  "LabelExactMatchTopics",
                                  "Exact Match Topics"
                                )
                          }`}
                          duration={recordingDuration}
                          segments={exactMatchTopicInsights}
                          onTrackSpanPlay={onRecordingTrackSpanPlay}
                          error={recTopicsError}
                          isAIAnalysed={callDetail?.recordings[0].isAIAnalysed}
                        />
                        <RecordingTrack
                          trackLabel={`${
                            translationsLoading
                              ? "Sentiments"
                              : fetchLabelKeyTranslation(
                                  "TextSentiments",
                                  "Sentiments"
                                )
                          }`}
                          duration={recordingDuration}
                          segments={sentimentsTrackInsight}
                          onTrackSpanPlay={onRecordingTrackSpanPlay}
                          error={recSentimentsError}
                          isAIAnalysed={callDetail?.recordings[0].isAIAnalysed}
                        />
                      </div>
                    )}
                    {!audioLoaded && !audioLoadedErrored && (
                      <div className="d-flex align-items-center justify-content-center">
                        <Loader type={"infinite-spinner"} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="settingData k-custom-tab p-t-5 p-b-20">
                    <TabStrip
                      tabPosition={"left"}
                      selected={selectedTab}
                      onSelect={handleSelect}
                    >
                      <TabStripTab
                        title={`${
                          translationsLoading
                            ? "Transcript"
                            : fetchLabelKeyTranslation(
                                "TranscriptTabTitle",
                                "Transcript"
                              )
                        }`}
                      >
                        <Transcription
                          rawTranscript={rawTranscript}
                          rawTranscriptError={rawTranscriptError}
                          sentimentsTrackInsight={sentimentsTrackInsight}
                          playbackProgress={playbackProgress}
                          secondParties={callDetail?.secondParties}
                          callDatetime={callDetail?.callTime}
                          isAIAnalysed={!showUpgradeLicense}
                          shared={shared}
                          translationsLoading={translationsLoading}
                          fetchLabelKeyTranslation={fetchLabelKeyTranslation}
                          lockedSegmentCount={
                            callDetail?.recordings[0]?.lockedSegments?.length ??
                            0
                          }
                        />
                      </TabStripTab>
                      {commentShared && (
                        <TabStripTab
                          title={`${
                            translationsLoading
                              ? "Comments"
                              : fetchLabelKeyTranslation(
                                  "CommentsTabTitle",
                                  "Comments"
                                )
                          }`}
                        >
                          <RecordingComments
                            comments={recordingComments}
                            error={recordingCommentsError}
                            recordingId={parseInt(recordingId!)}
                            regionSelection={regionSelection}
                            recordingDuration={recordingDuration}
                            onCommentMouseOver={onCommentMouseOver}
                            onCommentMouseOut={onCommentMouseOut}
                            audioLoaded={audioLoaded}
                            disableCommentPlay={audioLoaded === false}
                            stopCommentPlay={playCommentRegion === undefined}
                            playedCommentId={
                              playCommentRegion ? playCommentRegion.id : -1
                            }
                            onUserCommentPlay={onUserCommentPlay}
                            onUserCommentStop={onUserCommentStop}
                            onAddComment={onAddComment}
                            onDeleteComment={onDeleteComment}
                            onUpdateComment={onUpdateComment}
                            shared={shared}
                            platformCallDetailId={platformCallDetailId}
                            recordingPath={
                              window.location.pathname + window.location.search
                            }
                            baseLicenseId={
                              callDetail?.recordings[0].baseLicenseId
                            }
                            totalCommentsTemp={totalCommentsTemp}
                            setTotalCommentsTemp={setTotalCommentsTemp}
                          />
                        </TabStripTab>
                      )}
                      {evalShared && (
                        <TabStripTab
                          title={`${
                            translationsLoading
                              ? "Evaluation"
                              : fetchLabelKeyTranslation(
                                  "SCTabTitle",
                                  "Evaluation"
                                )
                          }`}
                        >
                          <RecordingScorecardTab
                            isCallEvaluated={
                              callDetail ? callDetail.isEvaluated : false
                            }
                            shared={shared}
                            recordingId={parseInt(recordingId!)}
                            onScorecardEvaluated={onScorecardEvaluated}
                            allocatedScorecards={
                              currentUserData?.scorecards ?? []
                            }
                            baseLicenseId={
                              callDetail
                                ? callDetail.recordings[0].baseLicenseId
                                : 0
                            }
                          />
                        </TabStripTab>
                      )}
                      {outcomeShared && (
                        <TabStripTab
                          title={`${
                            translationsLoading
                              ? "Call Outcomes"
                              : fetchLabelKeyTranslation(
                                  "TitleCallDetail",
                                  "Call Outcomes"
                                )
                          }`}
                        >
                          {callDetailLoading && (
                            <ContentPlaceholder>
                              <Loader type={"infinite-spinner"} />
                            </ContentPlaceholder>
                          )}
                          {callDetailError && (
                            <ContentPlaceholder>
                              <span>
                                <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                                {`${
                                  translationsLoading
                                    ? "Uh Oh! Something went wrong. Please try again... "
                                    : fetchLabelKeyTranslation(
                                        "CallDetailLoadFailedMsg",
                                        "Uh Oh! Something went wrong. Please try again... "
                                      )
                                }`}
                              </span>
                            </ContentPlaceholder>
                          )}
                          {callDetail && (
                            <CallSummary
                              callDetail={callDetail}
                              shared={shared}
                              onUpdateCallDetailProperties={
                                onUpdateCallDetailProperties
                              }
                            />
                          )}
                        </TabStripTab>
                      )}
                      {participantHistoryShared && (
                        <TabStripTab
                          title={`${
                            translationsLoading
                              ? "Participant History"
                              : fetchLabelKeyTranslation(
                                  "CallHistoryTabTitle",
                                  "Participant History"
                                )
                          }`}
                        >
                          <CallHistory
                            secondParties={callDetail?.secondParties}
                            shared={shared}
                            callDetail={callDetail}
                            setAlertData={setAlertData}
                          />
                        </TabStripTab>
                      )}
                      {/* PLEASE DO NOT DELETE. UNCOMMENT TO SHOW ANALYTICS TAB */}
                      {/* {callDetail?.platform.id === 5 && (
                        <TabStripTab title={<IcsAnalytics tab />}>
                          <div className="callLegs">
                            <div className="row">
                              <div className="col-md-12 m-b-15">
                                <div className="card cardEffect">
                                  <div className="card-header d-flex justify-content-between align-items-center p-t-7 p-b-8">
                                    <div className="titleText">
                                      <i className="bi bi-people-fill p-r-5"></i>
                                      <span className="fs-13">
                                        Call Details
                                      </span>
                                    </div>
                                  </div>
                                  <div className="card-body p-0">
                                    <div className="kpiBody">
                                      <div className="kpi-row">
                                        <div className="kpi-value-details">
                                          <div className="kpi-left-info">
                                            <div className="kpi-row-info">
                                              <div className="kpi-text">
                                                User
                                              </div>
                                              <div className="kpi-value">
                                                <label id="lblUser">
                                                  Clarissa Wind
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="kpi-right-info">
                                            <div className="kpi-row-info">
                                              <div className="kpi-text">
                                                Number
                                              </div>
                                              <div className="kpi-value">
                                                <label id="lblNumber">
                                                  +441895478899
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="kpi-row">
                                        <div className="kpi-value-details">
                                          <div className="kpi-left-info">
                                            <div className="kpi-row-info">
                                              <div className="kpi-text">
                                                DDI
                                              </div>
                                              <div className="kpi-value">
                                                <label id="lblDDI">
                                                  Serial Routing Group
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="kpi-right-info">
                                            <div className="kpi-row-info">
                                              <div className="kpi-text">
                                                Wait Time
                                              </div>
                                              <div className="kpi-value">
                                                <label id="lblWaitTime">
                                                  00:00:20
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card cardEffect">
                                  <div className="card-header d-flex justify-content-between align-items-center p-t-5 p-b-6">
                                    <div className="titleText">
                                      <i className="bi bi-diagram-2-fill fs-20 p-r-5"></i>
                                      <span className="fs-13">Call Legs</span>
                                    </div>
                                  </div>
                                  <div className="card-body p-0">
                                    <div className="tableList rowSpace float-left w-100">
                                      <div className="tableMain">
                                        <Grid
                                          style={{
                                            height: "420px",
                                            overflowY: "auto",
                                          }}
                                          data={products}
                                        >
                                          <GridColumn
                                            field="ProductID"
                                            title="User"
                                            cell={CustomCellUser}
                                            width="220px"
                                          />
                                          <GridColumn
                                            field="ProductDate"
                                            title="Date"
                                          />
                                          <GridColumn
                                            field="ProductTime"
                                            title="Time"
                                            // width="120px"
                                          />
                                          <GridColumn
                                            field="ProductDDI"
                                            title="DDI"
                                          />
                                          <GridColumn
                                            field="ProductNumber"
                                            title="Number"
                                          />
                                          <GridColumn
                                            field="ProductDuration"
                                            title="Duration"
                                          />
                                          <GridColumn
                                            field="ProductOutcome"
                                            title="Outcome"
                                          />
                                          <GridColumn
                                            field="ProductPresence"
                                            title="Presence"
                                          />
                                        </Grid>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabStripTab>
                      )} */}
                      {auditLogsShared &&
                        auth?.checkUserAccess(
                          AccessPermissionEnum.ManageAccount
                        ) && (
                          <TabStripTab
                            title={`${
                              translationsLoading
                                ? "Playback Audit"
                                : fetchLabelKeyTranslation(
                                    "PlaybackAuditTabTitle",
                                    "Playback Audit"
                                  )
                            }`}
                          >
                            <AuditLogSettings
                              callDetail={callDetail}
                              title={false}
                              filters={false}
                              search={false}
                              excludedColumns={[
                                "EntityType",
                                "entityFriendlyName",
                              ]}
                            />
                          </TabStripTab>
                        )}
                    </TabStrip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallDetails;
