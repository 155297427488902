import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox } from "@progress/kendo-react-inputs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import CustomComboBox from "../../../components/custom/form/CustomComboBox";
import useAuth from "../../../hooks/useAuth";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import customerService from "../../../services/customer.service";
import {
  CustomerCipherDetail,
  CustomerCLIMasking,
} from "../../../types/customer";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import { AccessPermissionEnum } from "../../../enums";
import useBranding from "../../../hooks/useBranding";
import useSwal from "../../../hooks/useSwal";
import useMasterData from "../../../hooks/useMasterData";

interface MaskFetchErrorTransalation {
  key: string;
  text: string;
}

const maskDigitsCount = [1, 2, 3, 4, 5, 6, 7];

const validateNoOfDigitsToMask = (value: string): string => {
  if (!value) {
    return "Please select number of digits to mask";
  }
  return "";
};

const SecurityPrivacySettings = () => {
  const masterData = useMasterData();
  const auth = useAuth();
  const localeCtx = useLocale();
  const swal = useSwal();
  const brandingCtx = useBranding();
  const [customerCLIMasking, setCustomerCLIMasking] =
    useState<CustomerCLIMasking>();
  const [maskingPolicies, setMaskingPolicies] = useState<string>();
  const [customerCipherDetail, setCustomerCipherDetail] =
    useState<CustomerCipherDetail>();
  const formRef = useRef<any>();
  const [userMaskUpserting, setUserMaskUpserting] = useState<boolean>(false);
  const [maskLoading, setMaskLoading] = useState<boolean>(false);
  const [cipherDetailsLoading, setCipherDetailsLoading] =
    useState<boolean>(false);
  const [cipherDetailsFetchError, setCipherDetailsFetchError] =
    useState<string>();
  const [maskUpserting, setMaskUpserting] = useState<boolean>(false);
  const [generatingKeys, setGeneratingKeys] = useState<boolean>(false);
  const [maskingError, setMaskingError] = useState<string>();
  const [value, copy] = useCopyToClipboard();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "SecurityPrivacySettings"
    ]
  );
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);
  const [maskFetchErrorTransalation, setMaskFetchErrorTransalation] =
    useState<MaskFetchErrorTransalation>();

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "SecurityPrivacySettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const fetchCustomerCLIMasking = async (customerId: number) => {
      try {
        setMaskLoading(true);
        const masking = await customerService.getCustomerCLIMasking(customerId);
        setCustomerCLIMasking(masking);
        buildMaskingPolicyLabel(masking);
      } catch (err) {
        console.error(err);
        if (err instanceof AxiosError) {
          if (err.response?.status === 404) {
            setMaskFetchErrorTransalation({
              key: "NoCLIFoundErr",
              text: translationsLoading
                ? "CLI Masking configured"
                : fetchLabelKeyTranslation(
                    "SwtAltSuccessTitleOne",
                    "CLI Masking configured"
                  ),
            });
          } else {
            setMaskFetchErrorTransalation({
              key: "",
              text: err.response?.data?.error ?? err.message,
            });
          }
        }
      } finally {
        setMaskLoading(false);
      }
    };

    const fetchCustomerCipherDetail = async (customerId: number) => {
      try {
        setCipherDetailsLoading(true);
        const cipherDetail = await customerService.getCustomerCipherDetail(
          customerId
        );
        setCustomerCipherDetail(cipherDetail);
      } catch (err) {
        console.error(err);
        if (err instanceof AxiosError) {
          setCipherDetailsFetchError(err.response?.data?.error ?? err.message);
        }
      } finally {
        setCipherDetailsLoading(false);
      }
    };

    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      fetchCustomerCLIMasking(customerId);
      fetchCustomerCipherDetail(customerId);
    }
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "SecurityPrivacySettings"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "SecurityPrivacySettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("SecurityPrivacySettings");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
          iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
          cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
          confirmButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const onCopyClick = async (text: string) => {
    try {
      const success = await copy(text);
    } catch (err) {
      console.error(err);
    }
  };

  const buildMaskingPolicyLabel = (masking: CustomerCLIMasking) => {
    const policies: string[] = [];
    if (masking.maskInbound) {
      policies.push("Mask Inbound");
    }
    if (masking.maskOutbound) {
      policies.push("Mask Outbound");
    }
    if (masking.maskInternal) {
      policies.push("Mask Internal");
    }
    if (policies.length > 0) {
      setMaskingPolicies(policies.join(" | "));
    }
  };

  const onMaskingSubmitHandler = async (dataItem: { [name: string]: any }) => {
    try {
      setMaskUpserting(true);
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId) {
        if (
          dataItem.maskInbound ||
          dataItem.maskOutbound ||
          dataItem.maskInternal
        ) {
          let updatedCLIMasking: CustomerCLIMasking = {
            ...customerCLIMasking,
            id: customerCLIMasking ? customerCLIMasking.id : 0,
            maskInbound: dataItem.maskInbound,
            maskOutbound: dataItem.maskOutbound,
            maskInternal: dataItem.maskInternal,
            noOfDigitsToMask: dataItem.noOfDigitsToMask,
          };

          let updatedCustomer = await customerService.upsertCustomerCLIMasking(
            customerId,
            updatedCLIMasking
          );
          setCustomerCLIMasking(updatedCustomer.cliMaskingDetail);
          buildMaskingPolicyLabel(updatedCustomer.cliMaskingDetail);
          setUserMaskUpserting(false);
          swal.fire({
            icon: "success",
            confirmButtonText: translationsLoading
              ? "Ok"
              : fetchLabelKeyTranslation("SwtAltOkText", "Ok"),
            title: translationsLoading
              ? "CLI Masking configured"
              : fetchLabelKeyTranslation(
                  "SwtAltSuccessTitleOne",
                  "CLI Masking configured"
                ),
            iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
            cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
            confirmButtonColor:
              brandingCtx?.branding?.themeColors[0].colorValue,
          });
        } else {
          setMaskingError(
            translationsLoading
              ? "Please select atleast one masking policy"
              : fetchLabelKeyTranslation(
                  "SwtAltErrorTextOne",
                  "Please select atleast one masking policy"
                )
          );
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: translationsLoading
            ? "Error"
            : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
          text: `${err.message}`,
          iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
          cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
          confirmButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
        });
      }
    } finally {
      setMaskUpserting(false);
    }
  };

  const onMaskUpsertCancelHandler = () => {
    setUserMaskUpserting(false);
    const form = formRef.current as Form;
    form.resetForm();
    setMaskingError(undefined);
  };

  const onUserUpsertMaskClickHandler = () => {
    setUserMaskUpserting(true);
    setMaskingError(undefined);
  };

  const deleteCustomerCLIMasking = async () => {
    var res = await swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
      cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
      confirmButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (res.isConfirmed) {
      customerService
        .deleteCustomerCLIMasking(auth?.tokenPayload?.CustomerId ?? 0)
        .then(() => {
          setCustomerCLIMasking(undefined);
          swal.fire({
            title: "Success",
            text: "Customer CLI masking is deleted successfully",
            icon: "success",
            iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
            cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
            confirmButtonColor:
              brandingCtx?.branding?.themeColors[0].colorValue,
          });
        })
        .catch((err) => {
          swal.fire({
            title: "Failed",
            text: "Something went wrong!",
            icon: "error",
            iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
            cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
            confirmButtonColor:
              brandingCtx?.branding?.themeColors[0].colorValue,
          });
        });
    }
  };

  const onUserGenerateKeys = async () => {
    let confirmed = true;
    if (customerCipherDetail) {
      const res = await swal.fire({
        title: translationsLoading
          ? "Are you Sure?"
          : fetchLabelKeyTranslation(
              "SwtAltGenerateKeysTitle",
              "Are you Sure?"
            ),
        text: translationsLoading
          ? "This will generate a new set of keys for encryption. You won't be able to revert this!"
          : fetchLabelKeyTranslation(
              "SwtAltGenerateKeysText",
              "This will generate a new set of keys for encryption. You won't be able to revert this!"
            ),
        icon: "warning",
        showCancelButton: true,
        iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
        cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
        confirmButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
        confirmButtonText: translationsLoading
          ? "Yes Generate keys"
          : fetchLabelKeyTranslation(
              "SwtAltConfirmBtnText",
              "Yes Generate keys"
            ),
        cancelButtonText: translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("SwtAltCancelBtnText", "Cancel"),
      });

      confirmed = res.isConfirmed;
    }

    if (confirmed) {
      try {
        const customerId = auth?.tokenPayload?.CustomerId;
        if (customerId) {
          setGeneratingKeys(true);
          const resp = await customerService.upsertCustomerCipherDetail(
            customerId
          );
          setCustomerCipherDetail(resp.cipherDetail);
          swal.fire({
            icon: "success",
            confirmButtonText: translationsLoading
              ? "Ok"
              : fetchLabelKeyTranslation("SwtAltOkText", "Ok"),
            title: translationsLoading
              ? "New Sets of Keys Generated successfully"
              : fetchLabelKeyTranslation(
                  "SwtAltGenerateKeysTitle2",
                  "New Sets of Keys Generated successfully"
                ),
            iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
            cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
            confirmButtonColor:
              brandingCtx?.branding?.themeColors[0].colorValue,
          });
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          console.error(err);
          swal.fire({
            icon: "error",
            title: translationsLoading
              ? "Error"
              : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
            text: `${err.message}`,
            iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
            cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
            confirmButtonColor:
              brandingCtx?.branding?.themeColors[0].colorValue,
          });
        }
      } finally {
        setGeneratingKeys(false);
      }
    }
  };

  const compareState = () => {
    const form = formRef.current as Form;

    console.log(form.values);
    console.log(customerCLIMasking);

    if (
      customerCLIMasking?.maskInbound == form.values.maskInbound &&
      customerCLIMasking?.maskInternal == form.values.maskInternal &&
      customerCLIMasking?.maskOutbound == form.values.maskOutbound &&
      customerCLIMasking?.noOfDigitsToMask == form.values.noOfDigitsToMask
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {translationsLoading
                    ? "Security & Privacy Details"
                    : fetchLabelKeyTranslation(
                        "SecurityPrivacySettingsTitle",
                        "Security & Privacy Details"
                      )}
                </span>
              </div>

              <div className="accordionData float-left w-100">
                <div className="panelbar-wrapper">
                  <PanelBar animation={true}>
                    <PanelBarItem
                      title={
                        translationsLoading
                          ? "CLI Masking"
                          : fetchLabelKeyTranslation(
                              "CLIMaskingTitle",
                              "CLI Masking"
                            )
                      }
                      expanded={true}
                      icon="folder"
                    >
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="visibleDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="float-left w-100 text-right p-t-5">
                                {!userMaskUpserting &&
                                  !maskLoading &&
                                  auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageAccount
                                  ) && (
                                    <Button
                                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                                        masterData?.telepoDisableConfig
                                          ?.systemSetting.securityAndPrivacy
                                          .disableCLIMasking
                                          ? "disabledBtn"
                                          : "border-primary"
                                      }`}
                                      style={{ height: "26px" }}
                                      onClick={onUserUpsertMaskClickHandler}
                                      disabled={
                                        masterData?.telepoDisableConfig
                                          ?.systemSetting.securityAndPrivacy
                                          .disableCLIMasking
                                      }
                                    >
                                      <span className="p-r-2">
                                        {customerCLIMasking ? (
                                          <>
                                            {translationsLoading
                                              ? "Edit"
                                              : fetchLabelKeyTranslation(
                                                  "SecurityPrivacySettingsEditBtnText",
                                                  "Edit"
                                                )}
                                          </>
                                        ) : (
                                          <>
                                            {translationsLoading
                                              ? "Add"
                                              : fetchLabelKeyTranslation(
                                                  "SecurityPrivacySettingsAddBtnText",
                                                  "Add"
                                                )}
                                          </>
                                        )}
                                      </span>
                                      <i
                                        className={`bi bi-${
                                          customerCLIMasking ? "pencil" : "plus"
                                        } fs-16`}
                                      ></i>
                                    </Button>
                                  )}
                                {!maskLoading &&
                                  customerCLIMasking &&
                                  auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageAccount
                                  ) &&
                                  !userMaskUpserting && (
                                    <Button
                                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                                        masterData?.telepoDisableConfig
                                          ?.systemSetting.securityAndPrivacy
                                          .disableCLIMasking
                                          ? "disabledBtn"
                                          : "border-primary"
                                      }`}
                                      style={{ height: "26px" }}
                                      onClick={deleteCustomerCLIMasking}
                                      disabled={
                                        masterData?.telepoDisableConfig
                                          ?.systemSetting.securityAndPrivacy
                                          .disableCLIMasking
                                      }
                                    >
                                      <span className="p-r-2">Delete</span>
                                      <i className="bi bi-trash3"></i>
                                    </Button>
                                  )}
                              </div>
                            </div>
                          </div>
                          {maskLoading && (
                            <div
                              className="m-t-10 m-b-10"
                              style={{ textAlign: "center" }}
                            >
                              <Loader type={"infinite-spinner"} />
                            </div>
                          )}
                          {!maskLoading &&
                            !customerCLIMasking &&
                            !userMaskUpserting && (
                              <div
                                className="m-t-10 m-b-10"
                                style={{ textAlign: "center" }}
                              >
                                <span className="tx-red fs-14">
                                  {maskFetchErrorTransalation ? (
                                    translationsLoading ? (
                                      maskFetchErrorTransalation.text
                                    ) : (
                                      fetchLabelKeyTranslation(
                                        maskFetchErrorTransalation.key,
                                        maskFetchErrorTransalation.text
                                      )
                                    )
                                  ) : (
                                    <>
                                      {translationsLoading
                                        ? "No CLI masking policy configured! Please add a new CLI masking policy to mask calls."
                                        : fetchLabelKeyTranslation(
                                            "SecurityPrivacySettingsErrorText",
                                            "No CLI masking policy configured! Please add a new CLI masking policy to mask calls."
                                          )}{" "}
                                    </>
                                  )}
                                </span>
                              </div>
                            )}
                          {!maskLoading &&
                            (customerCLIMasking || userMaskUpserting) && (
                              <Form
                                onSubmit={onMaskingSubmitHandler}
                                ref={formRef}
                                initialValues={{
                                  maskInbound:
                                    customerCLIMasking?.maskInbound ?? false,
                                  maskOutbound:
                                    customerCLIMasking?.maskOutbound ?? false,
                                  maskInternal:
                                    customerCLIMasking?.maskInternal ?? false,
                                  noOfDigitsToMask:
                                    customerCLIMasking?.noOfDigitsToMask ?? 0,
                                }}
                                render={(formRenderProps: FormRenderProps) => (
                                  <FormElement>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="trRowData float-left w-100 p-b-5">
                                          <div className="trk-row">
                                            {maskingError && (
                                              <span className="tx-red">
                                                {maskingError}
                                              </span>
                                            )}
                                            <div className="trkCol d-flex align-items-center">
                                              <div className="trkCol-h font-weight-semi">
                                                {translationsLoading
                                                  ? "Masking Policy"
                                                  : fetchLabelKeyTranslation(
                                                      "MaskingPolicyField",
                                                      "Masking Policy"
                                                    )}
                                              </div>
                                              <div className="trkCol-dot">
                                                :
                                              </div>
                                              <div className="trkCol-p border-none">
                                                {userMaskUpserting ? (
                                                  <div className="keywordRow" style={{ display: "flex", flexWrap: "wrap"}}>
                                                    <Field
                                                      id="maskInbound"
                                                      name="maskInbound"
                                                      className="m-l-4"
                                                      value={formRenderProps.valueGetter(
                                                        "maskInbound"
                                                      )}
                                                      label={
                                                        translationsLoading
                                                          ? "Mask Inbound"
                                                          : fetchLabelKeyTranslation(
                                                              "MaskInboundCheckbox",
                                                              "Mask Inbound"
                                                            )
                                                      }
                                                      component={Checkbox}
                                                      onChange={compareState}
                                                    />
                                                    <Field
                                                      id="maskOutbound"
                                                      name="maskOutbound"
                                                      className="m-l-4"
                                                      value={formRenderProps.valueGetter(
                                                        "maskOutbound"
                                                      )}
                                                      label={
                                                        translationsLoading
                                                          ? "Mask Outbound"
                                                          : fetchLabelKeyTranslation(
                                                              "MaskOutboundCheckbox",
                                                              "Mask Outbound"
                                                            )
                                                      }
                                                      component={Checkbox}
                                                      onChange={compareState}
                                                    />
                                                    <Field
                                                      id="maskInternal"
                                                      name="maskInternal"
                                                      className="m-l-4"
                                                      value={formRenderProps.valueGetter(
                                                        "maskInternal"
                                                      )}
                                                      label={
                                                        translationsLoading
                                                          ? "Mask Internal"
                                                          : fetchLabelKeyTranslation(
                                                              "MaskInternalCheckbox",
                                                              "Mask Internal"
                                                            )
                                                      }
                                                      component={Checkbox}
                                                      onChange={compareState}
                                                    />
                                                  </div>
                                                ) : !maskingPolicies ? (
                                                  <span className="text-black-7">
                                                    {translationsLoading
                                                      ? "No Policy"
                                                      : fetchLabelKeyTranslation(
                                                          "NoPolicyText",
                                                          "No Policy"
                                                        )}
                                                  </span>
                                                ) : (
                                                  <span>{maskingPolicies}</span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="trk-row">
                                            <div className="trkCol d-flex align-items-center">
                                              <div className="trkCol-h font-weight-semi">
                                                {translationsLoading
                                                  ? "Number of digits to Mask"
                                                  : fetchLabelKeyTranslation(
                                                      "DigitsMaskText",
                                                      "Number of digits to Mask"
                                                    )}
                                              </div>
                                              <div className="trkCol-dot">
                                                :
                                              </div>
                                              <div className="trkCol-p border-none">
                                                {!userMaskUpserting ? (
                                                  <span
                                                    className={`m-l-2 ${
                                                      !customerCLIMasking
                                                        ? "text-black-7"
                                                        : ""
                                                    }`}
                                                  >
                                                    {customerCLIMasking?.noOfDigitsToMask ??
                                                      0}
                                                  </span>
                                                ) : (
                                                  <Field
                                                    id={"noOfDigitsToMask"}
                                                    name={"noOfDigitsToMask"}
                                                    className="comboBoxInput"
                                                    placeholder={
                                                      translationsLoading
                                                        ? "Please select ..."
                                                        : fetchLabelKeyTranslation(
                                                            "PlaceholderText",
                                                            "Please select ..."
                                                          )
                                                    }
                                                    data={maskDigitsCount}
                                                    value={formRenderProps.valueGetter(
                                                      "noOfDigitsToMask"
                                                    )}
                                                    component={CustomComboBox}
                                                    validator={
                                                      validateNoOfDigitsToMask
                                                    }
                                                    onChange={compareState}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {userMaskUpserting && (
                                            <div className="row">
                                              <div className="col-md-12 text-right p-b-5">
                                                <div className="btnRightDiv">
                                                  <Button
                                                    className="btn bg-black-5 m-r-5"
                                                    onClick={
                                                      onMaskUpsertCancelHandler
                                                    }
                                                  >
                                                    {translationsLoading
                                                      ? "Cancel"
                                                      : fetchLabelKeyTranslation(
                                                          "BtnCancelText",
                                                          "Cancel"
                                                        )}
                                                  </Button>
                                                  <Button
                                                    className={`btn bg-primary text-white ${
                                                      !formRenderProps.allowSubmit ||
                                                      !formRenderProps.modified ||
                                                      !formRenderProps.valid ||
                                                      buttonStateTracker ||
                                                      masterData
                                                        ?.telepoDisableConfig
                                                        ?.systemSetting
                                                        .securityAndPrivacy
                                                        .disableCLIMasking
                                                        ? "disabledBtn"
                                                        : ""
                                                    }`}
                                                    disabled={
                                                      !formRenderProps.allowSubmit ||
                                                      !formRenderProps.modified ||
                                                      !formRenderProps.valid ||
                                                      buttonStateTracker ||
                                                      masterData
                                                        ?.telepoDisableConfig
                                                        ?.systemSetting
                                                        .securityAndPrivacy
                                                        .disableCLIMasking
                                                    }
                                                  >
                                                    {maskUpserting ? (
                                                      <Loader
                                                        size={"small"}
                                                        type={
                                                          "infinite-spinner"
                                                        }
                                                        themeColor={"light"}
                                                      />
                                                    ) : (
                                                      <>
                                                        {translationsLoading
                                                          ? "Update"
                                                          : fetchLabelKeyTranslation(
                                                              "UpdateButton",
                                                              "Update"
                                                            )}
                                                      </>
                                                    )}
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </FormElement>
                                )}
                              />
                            )}
                        </div>
                      </div>
                    </PanelBarItem>
                    <PanelBarItem
                      title={
                        translationsLoading
                          ? "Key Management"
                          : fetchLabelKeyTranslation(
                              "KeyManagementTitle",
                              "Key Management"
                            )
                      }
                      animation={true}
                      icon="folder"
                    >
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="editableDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trk-row">
                                {cipherDetailsLoading && (
                                  <div
                                    className="m-t-10 m-b-10"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Loader type={"infinite-spinner"} />
                                  </div>
                                )}
                                {!cipherDetailsLoading &&
                                  cipherDetailsFetchError && (
                                    <div
                                      className="m-t-10 m-b-10"
                                      style={{ textAlign: "center" }}
                                    >
                                      <span className="tx-red fs-14">
                                        {cipherDetailsFetchError}
                                      </span>
                                    </div>
                                  )}
                                {!cipherDetailsLoading &&
                                  !cipherDetailsFetchError && (
                                    <div>
                                      {customerCipherDetail ? (
                                        <div className="trkCol d-flex align-items-center">
                                          <div className="trkCol-h font-weight-semi">
                                            {translationsLoading
                                              ? "Public Key"
                                              : fetchLabelKeyTranslation(
                                                  "PublicKeyText",
                                                  "Public Key"
                                                )}
                                          </div>
                                          <div className="trkCol-dot">:</div>
                                          <div className="trkCol-p border-none">
                                            <span>{`\u25CF`.repeat(20)}</span>
                                            <Button
                                              className="btn bg-transparent text-black-12 text-primary m-l-2 m-r-5 p-r-7 p-l-7"
                                              onClick={() =>
                                                onCopyClick(
                                                  customerCipherDetail.publicKey
                                                )
                                              }
                                            >
                                              {!value ? (
                                                <i
                                                  className="bi bi-files cursor-pointer m-l-1"
                                                  title={
                                                    translationsLoading
                                                      ? "Copy"
                                                      : fetchLabelKeyTranslation(
                                                          "CopyText",
                                                          "Copy"
                                                        )
                                                  }
                                                ></i>
                                              ) : (
                                                <i
                                                  className="bi bi-check-lg m-l-1"
                                                  title={
                                                    translationsLoading
                                                      ? "Copied"
                                                      : fetchLabelKeyTranslation(
                                                          "CopiedText",
                                                          "Copied"
                                                        )
                                                  }
                                                ></i>
                                              )}
                                            </Button>
                                            <Button
                                              className={`d-flex align-items-center float-right btn bg-transparent text-black-12 text-primary m-r-5 m-l-5 m-r-5 p-r-7 p-l-7 ${
                                                masterData?.telepoDisableConfig
                                                  ?.systemSetting
                                                  .securityAndPrivacy
                                                  .disableKeyManagement
                                                  ? "disabledBtn"
                                                  : "border-primary"
                                              }`}
                                              onClick={onUserGenerateKeys}
                                              disabled={
                                                masterData?.telepoDisableConfig
                                                  ?.systemSetting
                                                  .securityAndPrivacy
                                                  .disableKeyManagement
                                              }
                                            >
                                              <span className="p-r-5">
                                                {translationsLoading
                                                  ? "Re-generate Keys"
                                                  : fetchLabelKeyTranslation(
                                                      "RegenerateText",
                                                      "Re-generate Keys"
                                                    )}
                                              </span>
                                              <i className="bi bi-arrow-clockwise fs-14"></i>
                                            </Button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="trkCol d-flex align-items-center">
                                          <span className="tx-red">
                                            {translationsLoading
                                              ? "No Keys available! Please generate keys to encrypt rcordings."
                                              : fetchLabelKeyTranslation(
                                                  "NoKeysAvailableText",
                                                  "No Keys available! Please generate keys to encrypt rcordings."
                                                )}
                                          </span>
                                          <Button
                                            className={`btn bg-transparent text-black-12 text-primary m-r-5 m-l-5 m-r-5 p-r-7 p-l-7 ${
                                              masterData?.telepoDisableConfig
                                                ?.systemSetting
                                                .securityAndPrivacy
                                                .disableKeyManagement
                                                ? "disabledBtn"
                                                : "border-primary"
                                            }`}
                                            onClick={onUserGenerateKeys}
                                            disabled={
                                              masterData?.telepoDisableConfig
                                                ?.systemSetting
                                                .securityAndPrivacy
                                                .disableKeyManagement
                                            }
                                          >
                                            <span className="p-r-5">
                                              {generatingKeys ? (
                                                <Loader
                                                  size={"small"}
                                                  type={"infinite-spinner"}
                                                  themeColor={"light"}
                                                />
                                              ) : (
                                                <>
                                                  {translationsLoading
                                                    ? "Generate Keys"
                                                    : fetchLabelKeyTranslation(
                                                        "GenerateKeysBtnText",
                                                        "Generate Keys"
                                                      )}
                                                </>
                                              )}
                                            </span>
                                            <i className="bi bi-arrow-clockwise fs-14"></i>
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityPrivacySettings;
