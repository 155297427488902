import React, { useContext, useEffect, useState } from "react";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Link, useLocation } from "react-router-dom";
import {
  Grid,
  GridColumn,
  GridCellProps,
  GridNoRecords,
  GridRowProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { axiosWithAuth } from "../../../utils/customAxios";
import { Scorecard } from "../../../types/scorecard";
import {
  ScorecardAllocation,
  ScorecardAllocationInstance,
} from "../../../types/scorecard/ScorecardAllocation";
import { Loader } from "@progress/kendo-react-indicators";
import UpsertScorecardDialog from "./UpsertScorecardDialog";
import { AccessPermissionEnum } from "../../../enums/accessPermissionEnum";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import useAuth from "../../../hooks/useAuth";
import { SortDescriptor, orderBy, process } from "@progress/kendo-data-query";
import scorecardService from "../../../services/scorecard.service";
import { AxiosError } from "axios";
import { LocalStorageFilter } from "../../../types/localstorage-filters/LocalStorageFilter";
import useLocalStorage from "../../../hooks/useLocalStorage";
import NotFoundError from "../../error/NotFoundError";
import CustomSearchFieldTextInput from "../../../components/custom/form/CustomSearchFieldTextInput";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";

const CustomCellTopicName = (props: GridCellProps) => {
  return (
    <td className="uCardCol">
      <div className="tblUsr">
        <div className="topUsrAreaPic">
          <div className="topUsrAreaPic-tx">
            <span className="text-primary font-weight-bold cursor-default text-decoration-none float-left w-100 line-height-1">
              {props.dataItem.scorecard.scorecardName}
            </span>
          </div>
        </div>
      </div>
    </td>
  );
};
const CelltopicDescription = (props: GridCellProps) => {
  return (
    <td className="mx-th-tag">
      <div className="mx-td-spn line-height-2">
        {props.dataItem.scorecard.description}
      </div>
    </td>
  );
};

interface ScorecardWithAllocation {
  scorecard: Scorecard;
  allocation: ScorecardAllocation;
}

const Scorecards: React.FC = () => {
  const auth = useAuth();
  const localeCtx = useLocale();
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const location = useLocation();
  const swal = useSwal();
  const backActive = location?.state?.backActive === true;

  const [localStorageFilter, setLocalStorageFilter] =
    useLocalStorage<LocalStorageFilter>("localStorageFilterData", {
      initialDataStateFromLS: { skip: 0, take: 10 },
      searchTermFromLS: "",
      sortDataFromLS: [],
    });

  const [search, setSearch] = useState<string>(
    backActive ? localStorageFilter.searchTermFromLS : ""
  );
  const [scorecardList, setScorecardList] = useState<ScorecardWithAllocation[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [allocationsFetchedLoader, setAllocationsFetchedLoader] =
    useState(true);
  const [page, setPage] = useState(
    backActive ? localStorageFilter.initialDataStateFromLS : initialDataState
  );

  const [key, setKey] = useState(0);
  const [scorecardEdit, setScorecardEdit] = useState<
    ScorecardWithAllocation | undefined
  >();
  const handlePageChange = (event: any) => {
    setPage(event.page);
  };

  const [visible, setVisible] = React.useState<boolean>(false);
  const toggleDialog = () => {
    setVisible(!visible);
    setScorecardEdit(undefined);
  };
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["Scorecard"]);

  const [sort, setSort] = React.useState([] as SortDescriptor[]);

  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    setPage(initialDataState);
  };

  const getScorecardAllocation = async (
    scorecardId: number
  ): Promise<ScorecardAllocation> => {
    const response = await axiosWithAuth.get(
      `scorecard/${scorecardId}/allocation`
    );
    const allocation: ScorecardAllocation = response.data;
    return allocation;
  };

  const buildScorecardAllocations = async (
    scorecards: Scorecard[]
  ): Promise<ScorecardWithAllocation[]> => {
    const scorecardWithAllocations: ScorecardWithAllocation[] = [];
    for (let i = 0; i < scorecards.length; i++) {
      const allocation = await getScorecardAllocation(scorecards[i].id);
      const scoreCardWithAlloc: ScorecardWithAllocation = {
        scorecard: scorecards[i],
        allocation: allocation,
      };
      scorecardWithAllocations.push(scoreCardWithAlloc);
    }
    return scorecardWithAllocations;
  };

  const getAllScoreCard = async () => {
    try {
      const scorecards: Scorecard[] =
        await scorecardService.fetchAllScorecards();
      const scorecardsWithAllocationsTemp: ScorecardWithAllocation[] = [];
      for (let i = 0; i < scorecards.length; i++) {
        scorecardsWithAllocationsTemp.push({
          scorecard: scorecards[i],
          allocation: {
            users: [],
            groups: [],
            ScorecardName: { id: 0, name: "" },
          },
        });
      }
      setScorecardList(scorecardsWithAllocationsTemp);
      setLoading(false);
      const scWithAllocations = await buildScorecardAllocations(scorecards);
      setScorecardList(scWithAllocations);
      setAllocationsFetchedLoader(false);
    } catch (error) {
      setLoading(false);
      setAllocationsFetchedLoader(false);
      setScorecardList([]);
    }
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["Scorecard"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getAllScoreCard();
  }, []);

  const searchScorecard = (scWithAlloc: ScorecardWithAllocation) => {
    const searchVal = search.trim().toLocaleLowerCase();
    if (
      scWithAlloc.scorecard?.scorecardName
        ?.toLocaleLowerCase()
        .includes(searchVal)
    ) {
      return true;
    }
    if (
      scWithAlloc.scorecard.description?.toLocaleLowerCase().includes(searchVal)
    ) {
      return true;
    }

    return false;
  };

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("Scorecard");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["Scorecard"]
      );
      localeCtx?.setPreviousAppLocale("Scorecard");
      const swalOptions: SweetAlertOptions<any, any> = {
        icon: "error",
        title: "Error",
        text: "Couldn't Switch Language",
      };
      if (localeCtx?.localeSwitchFailed) {
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const rowRender = (
    row: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => {
    const expanded = props.dataItem.scorecard.isArchived;
    const trProps: any = {
      title: props.dataItem.scorecard.isArchived
        ? `${
            translationsLoading
              ? "Archived"
              : fetchLabelKeyTranslation("TextArchived", "Archived")
          }`
        : "",
      className: expanded ? "archiveRow" : "",
    };
    return React.cloneElement(
      row,
      { ...trProps },
      row.props.children as React.ReactNode
    );
  };

  const upsertScorecard = (
    isUpdated: boolean,
    scWithAllocationAllocation: ScorecardWithAllocation
  ) => {
    if (isUpdated) {
      const updatedList: ScorecardWithAllocation[] = [];
      scorecardList?.forEach((t) => {
        if (t.scorecard.id === scWithAllocationAllocation.scorecard.id) {
          updatedList.push(scWithAllocationAllocation);
        } else {
          updatedList.push(t);
        }
      });
      setScorecardList(updatedList);
    } else {
      const updatedScorecards = [...scorecardList, scWithAllocationAllocation];
      setScorecardList(updatedScorecards);
      if (updatedScorecards.length % page.take !== 0) {
        setPage({
          skip: Math.floor(updatedScorecards.length / page.take) * page.take,
          take: page.take,
        });
      }
    }
  };

  const commonMethods = {
    deleteScorecard: async (props: GridCellProps) => {
      // Call API
      const isUsed: boolean = await scorecardService.fetchScorecardUsage(
        props.dataItem.scorecard.id
      );
      if (isUsed) {
        // If user wants to archive
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "info",
          title: `${
            translationsLoading
              ? "Scorecard will be archived"
              : fetchLabelKeyTranslation(
                  "SwtAltSCNotDeletedTitle",
                  "Scorecard will be archived"
                )
          }`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `${
            translationsLoading
              ? "Yes, Archive it!"
              : fetchLabelKeyTranslation(
                  "SwtAltConfirmToArchiveButtonText",
                  "Yes, Archive it!"
                )
          }`,
          cancelButtonText: `${
            translationsLoading
              ? "Cancel"
              : fetchLabelKeyTranslation("CancelButtonText", "Cancel")
          }`,
        };
        const resp = await swal.fire(swalOptions);
        if (resp.isConfirmed) {
          try {
            // Archive
            const updatedScorecard: Scorecard = props.dataItem.scorecard;
            updatedScorecard.isArchived = true;
            const updatedSC = await scorecardService.updateScorecardById(
              props.dataItem.scorecard.id,
              updatedScorecard
            );
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "success",
              title: `${
                translationsLoading
                  ? "Scorecard has been archived successfully"
                  : fetchLabelKeyTranslation(
                      "SwtAltArchiveSuccessTitle",
                      "Scorecard has been archived successfully"
                    )
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("TextOK", "OK")
              }`,
            };
            swal.fire(swalOptions);
            const updatedList = scorecardList?.map((item) => {
              if (item.scorecard.id === props.dataItem.scorecard.id) {
                item.scorecard.isArchived = true;
              }
              return item;
            });
            setScorecardList(updatedList);
          } catch (err) {
            // Archiving failed
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "error",
              title: `${
                translationsLoading
                  ? "Error"
                  : fetchLabelKeyTranslation(
                      "SwtAltSCNotArchivedFailedTitle",
                      "Error"
                    )
              }`,
              text: `${
                translationsLoading
                  ? "Could not archive the Scorecard."
                  : fetchLabelKeyTranslation(
                      "SwtAltSCNotArchivedFailedText",
                      "Could not archive the Scorecard."
                    )
              }`,
            };
            swal.fire(swalOptions);
          }
        }
      } else {
        // If user wants to delete
        const swalOptions: SweetAlertOptions<any, any> = {
          title: `${
            translationsLoading
              ? "Scorecard will be deleted"
              : fetchLabelKeyTranslation(
                  "DeleteSwtAltConfirmationText",
                  "Scorecard will be deleted"
                )
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `${
            translationsLoading
              ? "Yes, delete it!"
              : fetchLabelKeyTranslation(
                  "SwtAltConfirmToDeleteButtonText",
                  "Yes, delete it!"
                )
          }`,
          cancelButtonText: `${
            translationsLoading
              ? "Cancel"
              : fetchLabelKeyTranslation("CancelButtonText", "Cancel")
          }`,
        };
        const res = await swal.fire(swalOptions);
        if (res.isConfirmed) {
          try {
            // Delete
            await scorecardService.deleteScorecardById(
              props.dataItem.scorecard.id
            );
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "success",
              title: `${
                translationsLoading
                  ? "Scorecard has been deleted successfully"
                  : fetchLabelKeyTranslation(
                      "SwtAltDeleteSuccessTitle",
                      "Scorecard has been deleted successfully"
                    )
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("TextOK", "OK")
              }`,
            };
            swal.fire(swalOptions);
            const updatedList = scorecardList?.filter((sc) => {
              return sc.scorecard.id !== props.dataItem.scorecard.id;
            });
            setScorecardList(updatedList);
            const gridDataResult = process(
              updatedList.filter((t) => searchScorecard(t)),
              page
            );
            if (gridDataResult.data.length === 0 && gridDataResult.total > 0) {
              const currentPageNumber = Math.floor(
                gridDataResult.total / page.take
              );
              setPage({
                skip:
                  gridDataResult.total % page.take === 0
                    ? (currentPageNumber - 1) * page.take
                    : currentPageNumber * page.take,
                take: page.take,
              });
            }
          } catch (err) {
            // Deleting failed
            if (err instanceof AxiosError) {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation(
                        "SwtAltSCNotDeletedFailedTitle",
                        "Error"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Could not delete the Scorecard."
                    : fetchLabelKeyTranslation(
                        "SwtAltSCNotDeletedFailedText",
                        "Could not delete the Scorecard."
                      )
                }`,
              };
              swal.fire(swalOptions);
            }
          }
        }
      }
    },
    toggleDialogEdit: (props: GridCellProps) => {
      setScorecardEdit(props.dataItem);
      setVisible(!visible);
    },
  };

  const customCellMobile = (props: GridCellProps) => {
    const hasSCMangPErmission = auth?.checkUserAccess(
      AccessPermissionEnum.ManageScorecards
    );

    return (
      <td className="listMobile">
        <div className="listRowMobile">
          <div className="tblUsr float-left w-100 p-r-40 p-b-15">
            <div className="topUsrAreaPic">
              <div className="topUsrAreaPic-tx">
                <span className="text-primary fs-16 font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2">
                  {props.dataItem.scorecard.scorecardName}
                </span>
              </div>
            </div>
          </div>
          <div className="topics float-left w-100 p-t-0 p-b-10">
            <div className="fs-14 font-weight-semi p-r-5">
              {`${
                translationsLoading
                  ? "Description"
                  : fetchLabelKeyTranslation(
                      "SCDescriptionColHeader",
                      "Description"
                    )
              }`}
              :
            </div>
            <div className="chipLable line-height-2">
              {props.dataItem.scorecard.description}
            </div>
          </div>
          <div className="topics float-left w-100 p-t-5 p-b-8">
            <div className="fs-14 font-weight-semi p-r-15">
              {`${
                translationsLoading
                  ? "Assignment"
                  : fetchLabelKeyTranslation(
                      "SCAssignmentColHeader",
                      "Assignment"
                    )
              }`}
              :
            </div>
            <div className="chipLable">
              <div className="keywordRow">
                {props.dataItem.allocation.users?.map(
                  (obj: ScorecardAllocationInstance) => {
                    return (
                      <div key={"user" + obj.id} className="keywordTrk">
                        {obj.user?.firstName + " " + obj.user?.lastName}
                      </div>
                    );
                  }
                )}
                {props.dataItem.allocation.groups?.map(
                  (obj: ScorecardAllocationInstance) => {
                    return (
                      <div key={"group" + obj.id} className="keywordTrk">
                        {obj.group?.name}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="iconMobileDetail">
            {auth?.checkUserAccess(AccessPermissionEnum.ManageScorecards) && (
              <span
                className="fs-18 text-primary m-r-15"
                title={`${
                  translationsLoading
                    ? "Questions"
                    : fetchLabelKeyTranslation(
                        "ActionsColDetailIconTitle",
                        "Questions"
                      )
                }`}
              >
                <Link
                  to={`/settings/scorecardDetail/${props.dataItem.scorecard.id}`}
                  className="btn-link text-primary p-0 m-0 fs-20"
                >
                  <i className="bi bi-justify-left"></i>
                </Link>
              </span>
            )}

            <span
              className="fs-18 text-primary m-r-15"
              title={`${
                translationsLoading
                  ? "Edit"
                  : fetchLabelKeyTranslation("ActionsColEditIconTitle", "Edit")
              }`}
            >
              <Button
                className={`btn-link text-primary p-0 m-0 fs-18 ${
                  props.dataItem.scorecard.isArchived || !hasSCMangPErmission
                    ? "disabledIconBtn"
                    : "iconBtn"
                }`}
                disabled={
                  props.dataItem.scorecard.isArchived || !hasSCMangPErmission
                }
                onClick={() => commonMethods.toggleDialogEdit(props)}
              >
                <i className="bi bi-pencil"></i>
              </Button>
            </span>
            <span
              className="fs-18 text-primary m-r-15"
              title={`${
                translationsLoading
                  ? "Delete"
                  : fetchLabelKeyTranslation(
                      "ActionsColDeleteIconTitle",
                      "Delete"
                    )
              }`}
            >
              <Button
                className={`btn-link text-primary p-0 m-0 fs-18 ${
                  props.dataItem.scorecard.isArchived || !hasSCMangPErmission
                    ? "disabledIconBtn"
                    : "iconBtn"
                }`}
                onClick={() => commonMethods.deleteScorecard(props)}
                disabled={
                  props.dataItem.scorecard.isArchived || !hasSCMangPErmission
                }
              >
                <i className="bi bi-trash3"></i>
              </Button>
            </span>
          </div>
        </div>
      </td>
    );
  };

  const CustomCellWithActions = (props: GridCellProps) => {
    const hasSCMangPErmission = auth?.checkUserAccess(
      AccessPermissionEnum.ManageScorecards
    );

    const handleFilterData = () => {
      setLocalStorageFilter({
        initialDataStateFromLS: { skip: 0, take: 10 },
        searchTermFromLS: "",
        sortDataFromLS: [],
      });
      const data = {
        initialDataStateFromLS: page,
        searchTermFromLS: search,
        sortDataFromLS: [],
      };
      setLocalStorageFilter(data);
    };

    return (
      <td className="text-right">
        <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
          <span className="iBtn d-flex justify-content">
            {auth?.checkUserAccess(AccessPermissionEnum.ManageScorecards) && (
              <span
                className="m-r-10"
                title={`${
                  translationsLoading
                    ? "Questions"
                    : fetchLabelKeyTranslation(
                        "ActionsColDetailIconTitle",
                        "Questions"
                      )
                }`}
              >
                <Link
                  to={`/settings/scorecardDetail/${props.dataItem.scorecard.id}`}
                  className="btn-link text-primary p-0 m-0 fs-20"
                  onClick={handleFilterData}
                >
                  <i className="bi bi-justify-left"></i>
                </Link>
              </span>
            )}

            {props.dataItem.scorecard.isSystemDefined && (
              <Button
                style={{ cursor: "default" }}
                className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
              >
                <span
                  title={`${
                    translationsLoading
                      ? "System Defined"
                      : fetchLabelKeyTranslation(
                          "SystemDefinedIconTitle",
                          "System Defined"
                        )
                  }`}
                  className="fs-17 line-height-1 text-primary"
                >
                  <i className="bi bi-lock"></i>
                </span>
              </Button>
            )}

            {!props.dataItem.scorecard.isSystemDefined &&
              props.dataItem.scorecard.isArchived && (
                <Button
                  style={{ cursor: "default" }}
                  className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                >
                  <span
                    title={`${
                      translationsLoading
                        ? "Archived"
                        : fetchLabelKeyTranslation(
                            "ArchivedIconTitle",
                            "Archived"
                          )
                    }`}
                    className="fs-17 line-height-1 text-primary"
                  >
                    <i className="bi bi-archive"></i>
                  </span>
                </Button>
              )}

            {!props.dataItem.scorecard.isSystemDefined &&
              !props.dataItem.scorecard.isArchived && (
                <>
                  <Button
                    className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                    disabled={
                      props.dataItem.scorecard.isArchived ||
                      !hasSCMangPErmission
                    }
                    onClick={() => commonMethods.toggleDialogEdit(props)}
                  >
                    <span
                      className="fs-17 line-height-1 text-primary"
                      title={`${
                        translationsLoading
                          ? "Edit"
                          : fetchLabelKeyTranslation(
                              "ActionsColEditIconTitle",
                              "Edit"
                            )
                      }`}
                    >
                      <i className="bi bi-pencil"></i>
                    </span>
                  </Button>
                  <Button
                    className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                    onClick={() => commonMethods.deleteScorecard(props)}
                    disabled={
                      props.dataItem.scorecard.isArchived ||
                      !hasSCMangPErmission
                    }
                  >
                    <span
                      className="fs-17 line-height-1 text-primary"
                      title={`${
                        translationsLoading
                          ? "Delete"
                          : fetchLabelKeyTranslation(
                              "ActionsColDeleteIconTitle",
                              "Delete"
                            )
                      }`}
                    >
                      <i className="bi bi-trash3"></i>
                    </span>
                  </Button>
                </>
              )}
          </span>
        </Tooltip>
      </td>
    );
  };

  const CustomCellAssignment = (props: GridCellProps) => {
    const groups = props.dataItem.allocation.groups;
    const users = props.dataItem.allocation.users;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          {allocationsFetchedLoader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                textAlign: "center",
              }}
            >
              <Loader size={"small"} type={"infinite-spinner"} />
            </div>
          ) : (
            <div className="keywordRow">
              {users?.map((obj: ScorecardAllocationInstance) => {
                return (
                  <div key={"user" + obj.id} className="keywordTrk">
                    {obj.user?.firstName + " " + obj.user?.lastName}
                  </div>
                );
              })}
              {groups?.map((obj: ScorecardAllocationInstance) => {
                return (
                  <div key={"group" + obj.id} className="keywordTrk">
                    {obj.group?.name}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </td>
    );
  };

  const hCellDescription = (props: any) => {
    return <span>              {`${
      translationsLoading
        ? "Description"
        : fetchLabelKeyTranslation(
            "SCDescriptionColHeader",
            "Description"
          )
    }`}</span>;
  };
  const hCellAssignment = (props: any) => {
    return <span>{`${
      translationsLoading
        ? "Assignment"
        : fetchLabelKeyTranslation(
            "SCAssignmentColHeader",
            "Assignment"
          )
    }`}</span>;
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageScorecards) ? (
        <UnauthorizedAccess />
      ) : (
        <div key={key}>
        <div className="callListInner">
          <div className="callListBox">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card cardEffect cardMobile">
                      <div className="card-header d-flex justify-content-between p-0 p-b-12">
                        <span className="fs-13 font-weight-semi d-flex align-items-center">
                          {`${
                            translationsLoading
                              ? "Evaluation Scorecards"
                              : fetchLabelKeyTranslation(
                                  "EvaluationScorecardsGridTitle",
                                  "Evaluation Scorecards"
                                )
                          }`}
                        </span>
                        <div className="buttons-container hov-transparent d-flex align-itmes-center">
                          <div className="searchBox searchCol m-r-10">
                            <CustomSearchFieldTextInput
                              className="input-search"
                              placeholder={`${
                                translationsLoading
                                  ? "Search"
                                  : fetchLabelKeyTranslation(
                                      "SCSearchPlaceholderText",
                                      "Search"
                                    )
                              }`}
                              value={search}
                              updateValue={setSearch}
                              searchTextChangeHandler={onSearchChange}
                              onEscapeKeyFunc={true}
                            />
                          </div>
                          <Button
                            onClick={toggleDialog}
                            className={`btn bg-primary text-white fs-13 fw-normal ${
                              !auth?.checkUserAccess(
                                AccessPermissionEnum.ManageScorecards
                              )
                                ? "disabledBtn"
                                : ""
                            }`}
                            style={{ height: "29px", margin: "-1px 0 0 0" }}
                            disabled={
                              !auth?.checkUserAccess(
                                AccessPermissionEnum.ManageScorecards
                              )
                            }
                            title={`${
                              translationsLoading
                                ? "Add"
                                : fetchLabelKeyTranslation(
                                    "AddButtonText",
                                    "Add"
                                  )
                            }`}
                          >
                            {translationsLoading
                              ? "Add"
                              : fetchLabelKeyTranslation(
                                  "AddButtonText",
                                  "Add"
                                )}
                          </Button>
                          {visible && (
                            <UpsertScorecardDialog
                              toggleDialog={toggleDialog}
                              scorecardWithAllocation={scorecardEdit}
                              upsertScorecard={upsertScorecard}
                            />
                          )}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="tableList float-left w-100">
                          <div className="table-container table-responsive table-overflow-hedden highlightRowPlayer borderLeftSecondChild table-mobile">
                            <Tooltip
                              anchorElement="target"
                              parentTitle={true}
                              position="top"
                            >
                              <Grid
                                data={orderBy(
                                  scorecardList
                                    ?.filter((sc) => searchScorecard(sc))
                                    .slice(page.skip, page.take + page.skip) ??
                                    [],
                                  sort
                                )}
                                skip={page.skip}
                                take={page.take}
                                total={
                                  scorecardList.filter((sc) =>
                                    searchScorecard(sc)
                                  ).length
                                }
                                pageable={{ buttonCount: 4, pageSizes: true }}
                                onPageChange={handlePageChange}
                                rowRender={rowRender}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e: GridSortChangeEvent) => {
                                  let tempSort = e.sort;
                                  if (
                                    e.sort.length === 0 &&
                                    sort.length !== 0
                                  ) {
                                    tempSort = sort.map((s) => {
                                      return { field: s.field, dir: "asc" };
                                    });
                                  }
                                  setScorecardList(
                                    orderBy(scorecardList ?? [], tempSort)
                                  );
                                  setSort(tempSort);
                                  setKey(key==0?1:0);
                                }}
                              >
                                <GridNoRecords>
                                  {loading ? (
                                    <Loader type={"infinite-spinner"} />
                                  ) : (
                                    `${
                                      translationsLoading
                                        ? "No Records Available."
                                        : fetchLabelKeyTranslation(
                                            "NoRecordText",
                                            "No Records Available."
                                          )
                                    }`
                                  )}
                                </GridNoRecords>
                                <GridColumn
                                  width={0}
                                  field="cellMobile"
                                  cell={customCellMobile}
                                />
                                <GridColumn
                                  width={300}
                                  field="scorecard.scorecardName"
                                  title={`${
                                    translationsLoading
                                      ? "Scorecard Name"
                                      : fetchLabelKeyTranslation(
                                          "SCNameColHeader",
                                          "Scorecard Name"
                                        )
                                  }`}
                                  cell={CustomCellTopicName}
                                  sortable={true}
                                />
                                <GridColumn
                                  field="scorecard.description"
                                  title={`${
                                    translationsLoading
                                      ? "Description"
                                      : fetchLabelKeyTranslation(
                                          "SCDescriptionColHeader",
                                          "Description"
                                        )
                                  }`}
                                  cell={CelltopicDescription}
                                  sortable={false}
                                  headerCell={hCellDescription}
                                />
                                <GridColumn
                                  field="assignment"
                                  title={`${
                                    translationsLoading
                                      ? "Assignment"
                                      : fetchLabelKeyTranslation(
                                          "SCAssignmentColHeader",
                                          "Assignment"
                                        )
                                  }`}
                                  cell={CustomCellAssignment}
                                  sortable={false}
                                  headerCell={hCellAssignment}
                                />
                                <GridColumn
                                  sortable={true}
                                  width={150}
                                  field="scorecard.isArchived"
                                  title={`${
                                    translationsLoading
                                      ? "Actions"
                                      : fetchLabelKeyTranslation(
                                          "SCActionsColHeader",
                                          "Actions"
                                        )
                                  }`}
                                  cell={CustomCellWithActions}
                                />
                              </Grid>
                            </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Scorecards;
