import { useEffect, useState, useRef } from "react";
import { Popup } from "@progress/kendo-react-popup";
import moment from "moment";
import 'moment/min/locales';
import { Notification, NotificationTypeDetail } from "../../types/notification/Notification";
import { Loader } from "@progress/kendo-react-indicators";
import useAuth from "../../hooks/useAuth";
import notificationService from "../../services/notification.service";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { Dictionary } from "../../types/Dictionary";
import useMasterData from "../../hooks/useMasterData";
import { date } from "@progress/kendo-react-dateinputs/dist/npm/messages";
import useLocale from "../../hooks/useLocale";

interface INotificationProps {
  notificationActive: boolean;
  onNotificationToggle: () => void;
  translationsLoading: boolean;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
}

const Notifications: React.FC<INotificationProps> = ({
  notificationActive,
  onNotificationToggle,
  translationsLoading,
  fetchLabelKeyTranslation,
}) => {
  const auth = useAuth();
  const localeCtx = useLocale();
  const master = useMasterData();
  const notificationAnchor = useRef<any>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [unSeenNotifications, setUnSeenNotifications] = useState<number>(0);
  const [showUnread, setShowUnread] = useState<boolean>(false);
  const [endTimeStatus, setEndTimeStatus] = useState<string>("");
  const [latestNotificationsFetched, setLatestNotificationsFetched] =
    useState<Boolean>(false);
  const [allNotificationsFetched, setAllNotificationsFetched] =
    useState<Boolean>(false);
  const [callSeemore, setCallSeemore] = useState<Boolean>(false);

  useEffect(() => {
    moment.locale(localeCtx?.selectedLocale?.current.locale.code.split("-")[0]);
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (auth?.accessToken && master?.data?.notificationTypes) {
      fetchNotifications();
    }
  }, [master?.data?.notificationTypes]);

  useEffect(() => {
    if (!notificationActive) {
      let timer = setInterval(fetchNotifications, 60000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [notifications, notificationActive]);

  useEffect(() => {
    if (callSeemore) seemoreHandler();
    setCallSeemore(false);
  }, [callSeemore]);

  const createNotificationTitle = (
    notificationDetail: Dictionary<string> | undefined,
    notificationTypeDetail: NotificationTypeDetail
  ) => {
    const notificationType = master?.data?.notificationTypes.find(
      (x) => x.id === notificationTypeDetail.id
    );
    if (notificationType?.type === "Shared") {
      let shareTitle = `${
        translationsLoading
          ? "shared a recording with you."
          : fetchLabelKeyTranslation(
              "SharedTitle",
              "shared a recording with you."
            )
      }`;
      return `${
        notificationDetail ? notificationDetail["SharedBy"] : ""
      } ${shareTitle}`;
    } else if (notificationType?.type === "Tagged") {
      let tagTitle = `${
        translationsLoading
          ? "has tagged you in a comment."
          : fetchLabelKeyTranslation(
              "TaggedTitle",
              "has tagged you in a comment."
            )
      }`;
      //return "Tagged";
      return `${
        notificationDetail ? notificationDetail["TaggedBy"] : ""
      } ${tagTitle}`;
    } else {
      return `${
        translationsLoading
          ? "Unable to show this notification."
          : fetchLabelKeyTranslation(
              "UnableToShowText",
              "Unable to show this notification."
            )
      }`;
    }
  };

  const createNotificationText = (
    notificationTypeDetail: NotificationTypeDetail
  ) => {
    const notificationType = master?.data?.notificationTypes.find(
      (x) => x.id === notificationTypeDetail.id
    );
    if (notificationType?.type === "Shared") {
      return `${
        translationsLoading
          ? "Click to open recording."
          : fetchLabelKeyTranslation("SharedText", "Click to open recording.")
      }`;
    } else if (notificationType?.type === "Tagged") {
      return `${
        translationsLoading
          ? "Click to see comment."
          : fetchLabelKeyTranslation("TaggedText", "Click to see comment.")
      }`;
    } else {
      return "";
    }
  };
  const removeDuplicateNotifications = (newNotifications: Notification[]) => {
    const filteredRemoveDuplicateNotifications = newNotifications.reduce(
      (reducedArray: Notification[], r) => {
        let status = false;
        for (let i = 0; i < notifications.length; i++) {
          if (r.id === notifications[i].id) {
            status = true;
            break;
          }
        }
        if (!status) {
          reducedArray.push(r);
        }
        return reducedArray;
      },
      []
    );
    return filteredRemoveDuplicateNotifications;
  };

  const removeInvalidNotifications = (newNotifications: Notification[]) => {
    const typeIds = master?.data?.notificationTypes.map(
      (notificationType) => notificationType.id
    );
    if (typeIds && typeIds.length > 0) {
      const filteredRemoveInvalidNotifications = newNotifications.filter(
        (notification) => typeIds.includes(notification.notificationType.id)
      );
      return filteredRemoveInvalidNotifications;
    }
    return newNotifications;
  };

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      setError(undefined);
      const endTime = moment().format("yyyy-MM-DD HH:mm:ss");
      const startTime = moment()
        .subtract(7, "days")
        .format("yyyy-MM-DD HH:mm:ss");
      const response = await notificationService.getNotifications(
        endTime,
        startTime,
        false
      );
      const filteredRemoveInvalidNotifications =
        removeInvalidNotifications(response);
      const filteredRemoveDuplicateNotifications = removeDuplicateNotifications(
        filteredRemoveInvalidNotifications
      );
      if (filteredRemoveDuplicateNotifications.length < 10) {
        setNotifications([
          ...filteredRemoveDuplicateNotifications,
          ...notifications,
        ]);
      } else {
        setNotifications(filteredRemoveDuplicateNotifications);
      }
      const helper =
        filteredRemoveDuplicateNotifications.length < 10
          ? filteredRemoveDuplicateNotifications.concat(notifications)
          : filteredRemoveDuplicateNotifications;
      setUnSeenNotifications(helper.filter((n) => n.seen === false).length);
      if (filteredRemoveInvalidNotifications.length !== 0) {
        setEndTimeStatus(
          moment(helper[helper.length - 1].notifyAt)
            .subtract(1, "second")
            .format("yyyy-MM-DD HH:mm:ss")
        );
      } else {
        setLatestNotificationsFetched(true);
        setEndTimeStatus(
          moment().subtract(7, "days").format("yyyy-MM-DD HH:mm:ss")
        );
        setCallSeemore(true);
      }
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
        setError(ex.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onNotificationRead = (notification: Notification) => {
    markNotificationRead(notification);
    if (
      notification.notificationLink !== undefined &&
      notification.notificationLink.trim() !== ""
    ) {
      window.open(notification.notificationLink, "_blank", "noreferrer");
    }
  };

  const markNotificationRead = async (notification: Notification) => {
    try {
      var _notification: Notification = {
        ...notification,
        seen: true,
        notifyAt: moment(notification.notifyAt)
          .utc()
          .format("yyyy-MM-DD HH:mm:ss"),
        notificationReadAt: moment().utc().format("yyyy-MM-DD HH:mm:ss"),
      };
      const updatedNotification =
        await notificationService.updateSentNotification(_notification);
      if (updatedNotification) {
        const updatedNotifications: Notification[] = [];
        notifications.forEach((n) => {
          if (n.id === updatedNotification.id) {
            updatedNotifications.push(updatedNotification);
          } else {
            updatedNotifications.push(n);
          }
        });
        setNotifications(updatedNotifications);
        console.log(updatedNotification);
        setUnSeenNotifications(
          updatedNotifications.filter((n) => n.seen === false).length
        );
      }
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    }
  };

  const showUnreadHandler = (event: SwitchChangeEvent): void => {
    setShowUnread(event.value);
  };

  const markAllAsUnreadHandler = async () => {
    const ids = notifications.reduce<number[]>((idArray, n) => {
      idArray.push(n.id);
      return idArray;
    }, []);
    try {
      setLoading(true);
      setError(undefined);
      const response = await notificationService.clearNotifications(ids);
      if (response) {
        const updatedNotifications = notifications.map((n) => {
          return { ...n, seen: true };
        });
        setNotifications(updatedNotifications);
        setUnSeenNotifications(
          updatedNotifications.filter((n) => n.seen === false).length
        );
      }
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
        setError(ex.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const seemoreHandler = async () => {
    try {
      setLoading(true);
      setError(undefined);
      const endTime = endTimeStatus;
      const startTime = moment()
        .subtract(7, "days")
        .format("yyyy-MM-DD HH:mm:ss");
      const response = await notificationService.getNotifications(
        endTime,
        startTime,
        latestNotificationsFetched
      );
      const filteredRemoveInvalidNotifications =
        removeInvalidNotifications(response);
      if (filteredRemoveInvalidNotifications.length !== 0) {
        const filteredRemoveDuplicateNotifications =
          removeDuplicateNotifications(filteredRemoveInvalidNotifications);
        const helper = notifications.concat(
          filteredRemoveDuplicateNotifications
        );
        setNotifications([
          ...notifications,
          ...filteredRemoveDuplicateNotifications,
        ]);
        if (helper && helper.length > 0) {
          setEndTimeStatus(
            moment(helper[helper.length - 1].notifyAt)
              .subtract(1, "second")
              .format("yyyy-MM-DD HH:mm:ss")
          );
        }
        setUnSeenNotifications(helper.filter((n) => n.seen === false).length);
      } else if (
        filteredRemoveInvalidNotifications.length === 0 &&
        latestNotificationsFetched
      ) {
        setAllNotificationsFetched(true);
      } else {
        setLatestNotificationsFetched(true);
        setEndTimeStatus(
          moment().subtract(7, "days").format("yyyy-MM-DD HH:mm:ss")
        );
        setCallSeemore(true);
      }
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
        setError(ex.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <li
      className="nav-li dropdown p-relative cursor-pointer"
      ref={notificationAnchor}
    >
      {notificationActive ? (
        <i className="bi bi-bell-fill fs-20"></i>
      ) : (
        <i className="bi bi-bell fs-20" onClick={onNotificationToggle}></i>
      )}

      <span
        className={`notification ${unSeenNotifications > 0 && "bg-danger"}`}
      ></span>
      <Popup
        anchor={notificationAnchor.current}
        show={notificationActive}
        className="m-t-10"
      >
        <div
          className="dropMenu min-w-40 droplist-ul dropUlNotification"
          style={{
            padding: "0 5px 0 0",
          }}
        >
          <div className="notificationList">
            <div className="notificationList-t">
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="notificationTitle fs-18 mb-0 p-r-2">{`${
                  translationsLoading
                    ? "Notifications"
                    : fetchLabelKeyTranslation(
                        "TextNotifications",
                        "Notifications"
                      )
                }`}</p>
                {notifications.find((n) => n.seen === false) && (
                  <span className="notificationVal">
                    {unSeenNotifications > 10 ? "10+" : unSeenNotifications}
                  </span>
                )}
              </div>
              <div className="notification-switch">
                <p style={{ margin: "0 5px 0 0" }}>
                  {translationsLoading
                    ? "Only show unread"
                    : fetchLabelKeyTranslation(
                        "OnlyShowUnreadText",
                        "Only show unread"
                      )}
                </p>
                <Switch
                  checked={showUnread}
                  offLabel=""
                  onLabel=""
                  onChange={showUnreadHandler}
                />
              </div>
            </div>
            {unSeenNotifications > 0 && (
              <div
                className={
                  loading
                    ? "notification-markallread-loader"
                    : "notification-markallread"
                }
              >
                <p onClick={!loading ? markAllAsUnreadHandler : undefined}>
                  {translationsLoading
                    ? "Mark all as read"
                    : fetchLabelKeyTranslation(
                        "MarkAllReadText",
                        "Mark all as read"
                      )}
                </p>
              </div>
            )}

            <ul
              className="droplist-ul"
              style={{
                maxHeight: "335px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {notifications !== undefined && notifications.length > 0 ? (
                <>
                  {showUnread ? (
                    unSeenNotifications > 0 ? (
                      notifications.map((notification) => {
                        return (
                          <>
                            {!notification.seen && (
                              <li
                                className={`droplist-li ${
                                  notification.seen ? "" : "listViewd"
                                }`}
                                key={notification.id}
                              >
                                <div className="row p-t-3 p-b-3 p-l-14 p-r-5">
                                  <div>
                                    <i
                                      style={{
                                        color: !notification.seen
                                          ? "blue"
                                          : "white",
                                      }}
                                      className="bi bi-dot fa-2x"
                                    ></i>
                                  </div>
                                  <div
                                    style={{ paddingLeft: 0 }}
                                    className="col cursor-pointer"
                                    onClick={() =>
                                      onNotificationRead(notification)
                                    }
                                  >
                                    <div className="note-top-div d-flex">
                                      <span className="not-h">
                                        {createNotificationTitle(
                                          notification.notificationDetail,
                                          notification.notificationType
                                        )}
                                      </span>
                                      <span className="not-time">
                                        {moment(
                                          notification.notifyAt
                                        ).fromNow()}
                                      </span>
                                    </div>
                                    <p className="note-p mb-2">
                                      {createNotificationText(
                                        notification.notificationType
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <li className="text-center p-t-20 tx-dull">
                        {translationsLoading
                          ? "There are no unread notifications for you."
                          : fetchLabelKeyTranslation(
                              "NoNotificationText",
                              "There are no unread notifications for you."
                            )}
                      </li>
                    )
                  ) : (
                    notifications.map((notification) => {
                      return (
                        <li
                          className={`droplist-li ${
                            notification.seen ? "" : "listViewd"
                          }`}
                          key={notification.id}
                        >
                          <div className="row p-t-3 p-b-3 p-l-14 p-r-5">
                            <div>
                              <i
                                style={{
                                  color: !notification.seen ? "blue" : "white",
                                }}
                                className="bi bi-dot fa-2x"
                              ></i>
                            </div>
                            <div
                              style={{ paddingLeft: 0 }}
                              className="col cursor-pointer"
                              onClick={() => onNotificationRead(notification)}
                            >
                              <div className="note-top-div d-flex">
                                <span className="not-h">
                                  {createNotificationTitle(
                                    notification.notificationDetail,
                                    notification.notificationType
                                  )}
                                </span>
                                <span className="not-time">
                                  {moment(notification.notifyAt).fromNow()}
                                </span>
                              </div>
                              <p className="note-p mb-2">
                                {createNotificationText(
                                  notification.notificationType
                                )}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  )}
                  {!allNotificationsFetched &&
                    notifications.length !== 0 &&
                    !showUnread && (
                      <div
                        className={
                          loading
                            ? "notification-seemore-loader"
                            : "notification-seemore"
                        }
                      >
                        <p onClick={!loading ? seemoreHandler : undefined}>
                          {translationsLoading
                            ? "See more"
                            : fetchLabelKeyTranslation(
                                "SeeMoreText",
                                "See more"
                              )}
                        </p>
                      </div>
                    )}
                </>
              ) : !loading ? (
                <li className="text-center p-t-20 tx-dull">
                  {error &&
                    `${
                      translationsLoading
                        ? "Error fetching notifications!"
                        : fetchLabelKeyTranslation(
                            "NotificationErrorText",
                            "Error fetching notifications!"
                          )
                    }`}
                  {!error &&
                    notifications.length === 0 &&
                    `${
                      translationsLoading
                        ? "No new notifications"
                        : fetchLabelKeyTranslation(
                            "NotificationAllSeenText",
                            "No new notifications"
                          )
                    }`}
                </li>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Loader type={"infinite-spinner"} size="medium" />
                </div>
              )}
            </ul>
          </div>
        </div>
      </Popup>
    </li>
  );
};

export default Notifications;
